/* eslint-disable react/jsx-props-no-spreading */

import React from "react"
import { AutoComplete as AntdAutoComplete } from "antd"
import styled from "styled-components"

import colors from "../theme/colors"

const StyledAutocomplete = styled(AntdAutoComplete)`
	&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		border: 1px solid ${colors.grey};
	}

	&.ant-select-focused:not(.ant-select-disabled).ant-select-single:not(.ant-select-customize-input)
		.ant-select-selector {
		border: 1px solid ${colors.orange};
		outline-color: ${colors.orange};
		box-shadow: 0 0 0 2px rgba(${colors.orange_RGB}, 0.2);
	}
`

const Autocomplete = props => <StyledAutocomplete {...props} />

export default Autocomplete
