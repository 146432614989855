/* eslint-disable react/jsx-props-no-spreading */

import React from "react"
import { Select as AntdSelect } from "antd"
import styled from "styled-components"

import colors from "../theme/colors"

const StyledSelect = styled(AntdSelect)`
	&.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		border: 1px solid ${colors.grey};
	}

	.ant-select-arrow {
		font-size: 16px;
		color: ${colors.orange};
	}

	&.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
		border-color: ${colors.orange};
	}

	&.ant-select-focused.ant-select-single:not(.ant-select-customize-input)
		.ant-select-selector {
		box-shadow: 0 0 0 2px rgba(${colors.orange_RGB}, 0.2);
		border-color: ${colors.orange};
	}
`

const Select = props => <StyledSelect {...props} />

export default Select
