import React, { forwardRef, useImperativeHandle, useState } from "react"
import { Form } from "antd"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import styled from "styled-components"

import { updateUserDevices } from "../utils/firebaseHelpers"
import colors from "../theme/colors"

import Button from "./Button"
import Input from "./Input"
import Radio from "./Radio"
import Checkbox from "./Checkbox"

const StyledLabel = styled("span")`
	text-transform: capitalize;

	&.styled-group-label {
		color: ${colors.drakGrey};
		font-size: 16px;
	}

	&.styled-item-label {
		color: ${colors.darkGrey};
		font-size: 12px;
		font-family: "Poppins-SemiBold";
	}
`

const StyledButton = styled(Button)`
	font-family: "Poppins-SemiBold";
	text-transform: uppercase;
	min-width: 130px;
`

const StyledRadio = styled(Radio)`
	padding: 5px 0px;
`

const StyledFormItem = styled(Form.Item)`
	margin-bottom: 30px;
`

const UserDevicesForm = forwardRef((props, ref) => {
	const {
		onSuccess,
		userData,
		formName,
		hideActionButtons,
		saveOnFinish,
	} = props
	const [loading, setLoading] = useState(false)
	const [changed, setChanged] = useState(false)
	const [registerErrorMsg, setRegisterErrorMsg] = useState(null)
	const { t } = useTranslation()
	const [form] = Form.useForm()
	const [smartPhonesOtherField, setSmartPhonesOtherField] = useState(
		(userData.smartPhones && userData.smartPhones.some(s => s === "other")) ||
			false
	)
	const [tabletOtherField, setTabletOtherField] = useState(
		(userData.tablet && userData.tablet.some(s => s === "other")) || false
	)
	const [laptopOtherField, setLaptopOtherField] = useState(
		(userData.laptop && userData.laptop.some(s => s === "Other Model")) || false
	)
	const [voiceRecognitionOtherField, setVoiceRecognitionOtherField] = useState(
		(userData.voiceRecognition &&
			userData.voiceRecognition.some(s => s === "Other Model")) ||
			false
	)

	useImperativeHandle(ref, () => ({
		scrollToField(name) {
			if (form && form.scrollToField) {
				form.scrollToField(name, { behavior: "smooth" })
			}
		},

		async validateFields() {
			if (form && form.validateFields) {
				return form
					.validateFields()
					.then(values => ({ values, valid: true }))
					.catch(error => ({ valid: false, ...error }))
			}
			return null
		},
	}))

	const _saveForm = async values => {
		if (userData.id) {
			const { error } = await updateUserDevices(userData.id, values)
			if (error) {
				setLoading(false)
				setRegisterErrorMsg(t("common:somethingWrong"))
			} else if (onSuccess) {
				setLoading(false)
				setChanged(false)
				onSuccess()
			}
		}
	}

	const _onFinish = async values => {
		setRegisterErrorMsg(null)

		if (saveOnFinish) {
			_saveForm(values)
		}
	}

	const _onFinishFailed = () => {
		setLoading(false)
	}

	const _onReset = () => {
		form.resetFields()
		setSmartPhonesOtherField(
			(userData.smartPhones && userData.smartPhones.some(s => s === "other")) ||
				false
		)
		setTabletOtherField(
			(userData.tablet && userData.tablet.some(s => s === "other")) || false
		)
		setLaptopOtherField(
			(userData.laptop && userData.laptop.some(s => s === "Other Model")) ||
				false
		)
		setVoiceRecognitionOtherField(
			(userData.voiceRecognition &&
				userData.voiceRecognition.some(s => s === "Other Model")) ||
				false
		)
		setChanged(false)
	}

	return (
		<Form
			style={{
				margin: `auto`,
				maxWidth: `700px`,
			}}
			layout="vertical"
			name={formName || "devicesForm"}
			initialValues={{ remember: true }}
			onFinish={_onFinish}
			onFinishFailed={_onFinishFailed}
			form={form}
			onValuesChange={() => setChanged(true)}
			hideRequiredMark
		>
			<StyledFormItem
				label={
					<StyledLabel className="styled-group-label">
						{t("devices:smartPhones")}
					</StyledLabel>
				}
				name="smartPhones"
				validateTrigger="onSubmit"
				initialValue={userData.smartPhones || []}
			>
				<Checkbox.Group>
					<Checkbox value="iphone">{t("devices:iphone")}</Checkbox>
					<Checkbox value="android">{t("devices:android")}</Checkbox>
					<Checkbox value="jitterburg">{t("devices:jitterbug")}</Checkbox>
					<Checkbox
						value="other"
						onChange={event => setSmartPhonesOtherField(event.target.checked)}
					>
						{t("devices:otherModel")}
					</Checkbox>
				</Checkbox.Group>
			</StyledFormItem>

			<StyledFormItem
				hidden={!smartPhonesOtherField}
				name="smartPhonesOther"
				style={{ width: "50%" }}
				initialValue={userData.smartPhonesOther || ""}
			>
				<Input placeholder={t("forms:specifyOther")} />
			</StyledFormItem>

			<StyledFormItem
				label={
					<StyledLabel className="styled-group-label">
						{t("devices:tablets")}
					</StyledLabel>
				}
				name="tablet"
				initialValue={userData.tablet || []}
			>
				<Checkbox.Group>
					<Checkbox value="ipad">{t("devices:ipad")}</Checkbox>
					<Checkbox value="samsung_galaxy">
						{t("devices:samsungGalaxy")}
					</Checkbox>
					<Checkbox value="grandpad">{t("devices:grandpad")}</Checkbox>
					<Checkbox
						value="other"
						onChange={event => setTabletOtherField(event.target.checked)}
					>
						{t("devices:otherModel")}
					</Checkbox>
				</Checkbox.Group>
			</StyledFormItem>

			<StyledFormItem
				hidden={!tabletOtherField}
				name="tabletOther"
				style={{ width: "50%" }}
				initialValue={userData.tabletOther || ""}
			>
				<Input placeholder={t("forms:specifyOther")} />
			</StyledFormItem>

			<StyledFormItem
				label={
					<StyledLabel className="styled-group-label">
						{t("devices:laptop")}
					</StyledLabel>
				}
				name="laptop"
				validateTrigger="onSubmit"
				initialValue={userData.laptop || []}
			>
				<Checkbox.Group>
					<Checkbox value="Mac">{t("devices:mac")}</Checkbox>
					<Checkbox value="PC">{t("devices:pc")}</Checkbox>
					<Checkbox
						value="Other Model"
						onChange={event => setLaptopOtherField(event.target.checked)}
					>
						{t("devices:otherModel")}
					</Checkbox>
				</Checkbox.Group>
			</StyledFormItem>

			<StyledFormItem
				hidden={!laptopOtherField}
				name="laptopOther"
				style={{ width: "50%" }}
				initialValue={userData.laptopOther || ""}
			>
				<Input placeholder={t("forms:specifyOther")} />
			</StyledFormItem>

			<StyledFormItem
				label={
					<StyledLabel className="styled-group-label">
						{t("devices:voiceRecognition")}
					</StyledLabel>
				}
				name="voiceRecognition"
				validateTrigger="onSubmit"
				initialValue={userData.voiceRecognition || []}
			>
				<Checkbox.Group>
					<Checkbox value="Alexa">{t("devices:alexa")}</Checkbox>
					<Checkbox value="Google Home">{t("devices:googleHome")}</Checkbox>
					<Checkbox
						value="Other Model"
						onChange={event =>
							setVoiceRecognitionOtherField(event.target.checked)
						}
					>
						{t("devices:otherModel")}
					</Checkbox>
				</Checkbox.Group>
			</StyledFormItem>

			<StyledFormItem
				hidden={!voiceRecognitionOtherField}
				name="voiceRecognitionOther"
				style={{ width: "50%" }}
				initialValue={userData.voiceRecognitionOther || ""}
			>
				<Input placeholder={t("forms:specifyOther")} />
			</StyledFormItem>

			<StyledFormItem
				label={
					<StyledLabel className="styled-group-label">
						{t("devices:printers")}
					</StyledLabel>
				}
				name="printers"
				validateTrigger="onSubmit"
				initialValue={userData.printers || ""}
			>
				<Input />
			</StyledFormItem>

			<StyledFormItem
				label={
					<StyledLabel className="styled-group-label">
						{t("devices:routersWifi")}
					</StyledLabel>
				}
				name="router"
				validateTrigger="onSubmit"
				initialValue={userData.router || ""}
			>
				<Input />
			</StyledFormItem>

			<StyledFormItem
				label={
					<StyledLabel className="styled-group-label">
						{t("devices:homeSafetyDevices")}
					</StyledLabel>
				}
				name="homeSafetyDevices"
				validateTrigger="onSubmit"
				initialValue={userData.homeSafetyDevices || ""}
			>
				<Input />
			</StyledFormItem>

			<StyledFormItem
				label={
					<StyledLabel className="styled-group-label">
						{t("devices:haveCloudBackup")}
					</StyledLabel>
				}
				name="cloudBackup"
				validateTrigger="onSubmit"
				initialValue={userData.cloudBackup || ""}
			>
				<Radio.Group>
					<StyledRadio value="yes">{t("common:yes")}</StyledRadio>
					<StyledRadio value="no">{t("common:no")}</StyledRadio>
					<StyledRadio value="dont know">{t("common:dont_know")}</StyledRadio>
				</Radio.Group>
			</StyledFormItem>

			<StyledFormItem
				label={
					<StyledLabel className="styled-group-label">
						{t("devices:wouldHaveCloudBackup")}
					</StyledLabel>
				}
				name="interestedInCloudBackup"
				validateTrigger="onSubmit"
				initialValue={userData.interestedInCloudBackup || ""}
			>
				<Radio.Group>
					<StyledRadio value="yes">{t("common:yes")}</StyledRadio>
					<StyledRadio value="no">{t("common:no")}</StyledRadio>
					<StyledRadio value="maybe">{t("common:maybe")}</StyledRadio>
				</Radio.Group>
			</StyledFormItem>

			<Form.Item
				label={
					<StyledLabel className="styled-group-label">
						{t("common:hobbiesAndLifestyle")}
					</StyledLabel>
				}
				name="interests"
				validateTrigger="onSubmit"
				initialValue={userData.interests || ""}
			>
				<Input />
			</Form.Item>

			<StyledFormItem
				style={{ marginBottom: 0, textAlign: "center" }}
				validateStatus={registerErrorMsg ? "error" : "-"}
				help={registerErrorMsg}
			>
				{!hideActionButtons ? (
					<>
						<StyledFormItem style={{ display: "inline-block" }}>
							<StyledButton
								htmlType="button"
								type="cancel-form"
								shape="round"
								onClick={_onReset}
								disabled={!changed || loading}
							>
								{t("common:cancel")}
							</StyledButton>
						</StyledFormItem>

						<StyledFormItem
							style={{ display: "inline-block", marginLeft: "20px" }}
						>
							<StyledButton
								type="primary"
								htmlType="submit"
								shape="round"
								loading={loading}
								onClick={() => setLoading(true)}
								disabled={!changed}
							>
								{t("common:save")}
							</StyledButton>
						</StyledFormItem>
					</>
				) : null}
			</StyledFormItem>
		</Form>
	)
})

UserDevicesForm.displayName = "UserDevicesForm"

UserDevicesForm.propTypes = {
	onSuccess: PropTypes.func,
	saveOnFinish: PropTypes.bool,
	hideActionButtons: PropTypes.bool,
	formName: PropTypes.string,
	userData: PropTypes.shape({
		id: PropTypes.string,
		smartPhones: PropTypes.arrayOf(PropTypes.string),
		smartPhonesOther: PropTypes.string,
		tablet: PropTypes.arrayOf(PropTypes.string),
		tabletOther: PropTypes.string,
		laptop: PropTypes.arrayOf(PropTypes.string),
		laptopOther: PropTypes.string,
		voiceRecognition: PropTypes.arrayOf(PropTypes.string),
		voiceRecognitionOther: PropTypes.string,
		printers: PropTypes.string,
		router: PropTypes.string,
		homeSafetyDevices: PropTypes.string,
		interests: PropTypes.string,
		cloudBackup: PropTypes.string,
		interestedInCloudBackup: PropTypes.string,
	}),
}

UserDevicesForm.defaultProps = {
	onSuccess: null,
	userData: {},
	saveOnFinish: true,
	hideActionButtons: false,
	formName: null,
}

export default UserDevicesForm
