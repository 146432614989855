import React from "react"
import { Link } from "@reach/router"

import { Paths } from "../constants/RouterConstants"

const NotFoundView = () => {
	return (
		<div>
			<h1>Page not found</h1>
			<Link to={Paths.PROFILE}>Go to Profile</Link>
		</div>
	)
}

export default NotFoundView
