/* eslint-disable react/jsx-props-no-spreading */

import React from "react"
import { Collapse as AntdCollapse } from "antd"
import { RightOutlined } from "@ant-design/icons"
import styled from "styled-components"
import PropTypes from "prop-types"

import colors from "../theme/colors"

const StyledCollapse = styled(AntdCollapse)`
	.form-title {
		font-family: "Poppins-SemiBold";
	}

	&.ant-collapse {
		max-width: 700px;
		width: 100%;
		margin: auto;

		.header {
			font-family: "Poppins-SemiBold";
		}
	}
`

const Collapse = props => {
	const { expandIcon, ...rest } = props

	if (expandIcon) {
		rest.expandIcon = expandIcon
	} else {
		rest.expandIcon =
			expandIcon ||
			(({ isActive }) => (
				<RightOutlined
					style={{ color: colors.orange, fontSize: "12px" }}
					rotate={isActive ? 90 : 0}
				/>
			))
	}

	return <StyledCollapse {...rest} />
}

Collapse.displayName = "Collapse"

Collapse.propTypes = {
	expandIcon: PropTypes.func,
}

Collapse.defaultProps = {
	expandIcon: null,
}

export default Collapse
