import actions from "./actions"

const initialState = {
	product: null,
	products: null,
	carereceivers: null,
	accountHolder: null,
	hasCouple: null,
	isNewCustomer: null,
	additionalAgent: null,
	additionalAgent2: null,
	isCoupleProduct: null,
	isGiftcard: false,
	accountType: null,
	isInstallationAndSetup: false,
	error: null,
}

const reducer = (state, action) => {
	switch (action.type) {
		case actions.SET_COMMON_PAYLOAD:
			return {
				...state,
				...action.payload,
			}

		case actions.RESET:
			return {
				product: null,
				products: null,
				carereceivers: null,
				accountHolder: null,
				additionalAgent: null,
				additionalAgent2: null,
				isCoupleProduct: null,
				accountType: null,
				error: null,
			}

		default: {
			throw new Error(`Unhandled action type: ${action.type}`)
		}
	}
}

export { reducer, initialState }
