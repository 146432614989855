/* eslint-disable react/jsx-props-no-spreading */

import React from "react"
import { Input as AntdInput } from "antd"
import styled, { css } from "styled-components"

import colors from "../theme/colors"

const inputStyles = css`
	border: 1px solid ${colors.grey};

	&:hover {
		border-color: ${colors.orange};
	}

	&:focus,
	&.ant-input-focused {
		border-color: ${colors.orange};
		box-shadow: 0 0 0 2px rgba(${colors.orange_RGB}, 0.2);
	}

	&[disabled] {
		cursor: initial;
		background-color: ${colors.lightGrey};
		&:hover {
			border: 1px solid ${colors.grey};
		}
	}
`

const StyledInput = styled(AntdInput)`
	${inputStyles}
`

const StyledInputPassword = styled(StyledInput.Password)`
	${inputStyles}
`

const StyledInputTextArea = styled(StyledInput.TextArea)`
	${inputStyles}
`

const Input = props => <StyledInput {...props} />

Input.Password = props => <StyledInputPassword {...props} />
Input.Password.displayName = "InputPassword"

Input.TextArea = props => <StyledInputTextArea {...props} />
Input.TextArea.displayName = "InputTextArea"

export default Input
