export default {
	common: {
		emailAddress: `Email address`,
		password: `Password`,
		typeEmail: `Type in your email`,
		typePassword: `Type in your password`,
		confirmPassword: `Confirm your password`,
		welcomeBackUser: `Welcome back {{userName}}!`,
		welcome: `Welcome!`,
		signOut: `Sign Out`,
		somethingWrong: `Something went wrong. Try again.`,
		rememberMe: `Remember me`,
		hi: `Hi`,
		save: `Save`,
		cancel: `Cancel`,
		name: `Name`,
		firstName: `First name`,
		lastName: `Last name`,
		address: `Address`,
		state: `State`,
		city: `City`,
		zipCode: `Zipcode`,
		phone: `Phone`,
		hobbiesAndLifestyle: `Hobbies/Interests/Lifestyle`,
		livingConditions: `Living conditions`,
		liveAlone: `Live Alone`,
		liveWith: `Live with Spouse/roommate/child/other`,
		other: `Other`,
		howToBeContacted: `How would you like to be contacted to schedule and confirm visits?`,
		howToSummaries: `How would you like to receive Candoo visit summaries and follow ups?`,
		email: `Email`,
		regularMail: `Regular mail`,
		otherNotes: `Other notes for Candoo Team`,
		basicInformation: `Basic Information`,
		yourDevices: `Your Devices`,
		devices: `Devices`,
		yes: `Yes`,
		no: `No`,
		dont_know: `I don't know`,
		maybe: `Maybe`,
		dateTimeFormat: `MM-DD-YYYY HH:mm`,
		dateFormat: `MMM D, YYYY`,
		timeFormat: `h:mma`,
		dateTimeFormatSlash: `MM/DD/YYYY HH:mm`,
		dateFormatSlash: `MM/DD/YYYY`,
		delete: `Delete`,
		edit: `Edit`,
		discard: `Discard`,
		viewDetails: `View details`,
		readMore: `Read more`,
		PENDING: `Pending`,
		REJECTED: `Rejected`,
		ACCEPTED: `Accepted`,
		accept: `Accept`,
		reject: `Reject`,
		continue: `Continue`,
		passwordUsage: `You’re email and password will be used to access your account, check your plan details and update your profile at anytime.`,
	},

	screenLoader: {
		initializingApp: `Initializing app...`,
		loggingIn: `Logging in...`,
		loading: `Loading...`,
	},

	nav: {
		accountOverview: `Account overview`,
		activeProducts: `Products & Orders`,
		planMembers: `Plan Members`,
		appointments: `Appointments`,
		talkToSupport: `Talk to support`,
		orders: `Orders`,
		candooProducts: `Candoo products`,
		profileInfo: `Profile information`,
	},

	forms: {
		requiredField: `Field is required.`,
		emailInUse: `Email is already in use.`,
		invalidEmail: `Email is invalid.`,
		emailNotFound: `Email doesn't exists.`,
		wrongPassword: `Wrong password.`,
		passwordLength: `Password must have at least 6 characters.`,
		passwordsDontMatch: `Passwords don't match.`,
		invalidZip: `Zipcode is invalid.`,
		onlyNumbers: `Only numbers.`,
		onlyPhoneNumbers: `Only numbers and +`,
		changesSaved: `Changes saved!`,
		allowAccountHolder: `Allow my account holder to access and edit my profile information`,
		tooManyRequests: `You need to wait some time to resend the email`,
		optional: `optional`,
		specifyOther: `Specify other`,
	},

	devices: {
		smartPhones: `Smart Phones`,
		iphone: `iPhone`,
		android: `Android`,
		jitterbug: `Jitterbug`,
		otherModel: `Other Model`,
		tablets: `Tablets`,
		ipad: `Ipad`,
		samsungGalaxy: `Samsung Galaxy`,
		grandpad: `Grandpad`,
		laptop: `Laptop`,
		mac: `Mac`,
		pc: `Pc`,
		voiceRecognition: `Voice Recognition`,
		alexa: `Alexa`,
		googleHome: `Google Home`,
		printers: `Printers`,
		routersWifi: `Routers & Wifi`,
		homeSafetyDevices: `Home Safety Devices`,
		haveCloudBackup: `Do you have a cloud backup?`,
		wouldHaveCloudBackup: `If No, would you like us to install one?`,
	},

	register: {
		register: `Register`,
		repeatPassword: `Repeat Password`,
	},

	signIn: {
		signIn: `Sign In`,
		forgotPassword: `Forgot Password`,
	},

	resetPassword: {
		changePassword: "Change Password",
		label: `Click on the button bellow to change your password. An email will be sent with instructions.`,
		resetEmailSent: `Email sent!`,
		sendEmail: `Reset Password`,
		sendingEmail: `Sending Email`,
	},

	appointments: {
		calendar: `Appointments calendar`,
		newAppointment: `New appointment`,
		updateAppointment: `Update appointment`,
		pickDateTime: `Pick date and time`,
		firstChoice: `First choice`,
		secondChoice: `Second choice`,
		thirdChoice: `Third choice`,
		firstDateChoice: `first date choice`,
		secondDateChoice: `second date choice`,
		thirdDateChoice: `third date choice`,
		confirmedDate: `confirmed date`,
		user: `Appointment user`,
		agentList: `Agent list`,
		agentName: `Agent name`,
		appointmentType: `Appointment type`,
		description: `Description`,
		selectType: `Select a type`,
		selectAgent: `Select an agent`,
		selectUser: `Select a user`,
		describe: `Let us know what do you need help with`,
		created: `Appointment created!`,
		updated: `Appointment updated!`,
		canceled: `Appointment canceled!`,
		cancel: `Cancel appointment`,
		confirmCancel: `Are you sure to cancel this appointment?`,
		statusPending: `Pending confirmation`,
		statusConfirmed: `Confirmed`,
		details: `Appointment details`,
		edit: `Edit appointment`,
		upcomingSingular: `Upcoming appointment`,
		upcomingPlural: `Upcoming appointments`,
		pastSingular: `Past appointment`,
		requester: `Requester`,
		noAppointments: `You don't have appointments.`,
	},

	services: {
		"90minutesSession": `90 Minutes Session`,
		quickSupport: `Quick Support`,
		buyMoreServices: `Buy More Services`,
		seeAllProductsServices: `see all products and services`,
	},

	products: {
		familyMembership: `Family Membership`,
		singleMembership: `Single Membership`,
	},

	productMembers: {
		manageMembers: `Manage Product Members`,
		emptyList: `You don't have any product members`,
		editProfile: `Edit Profile`,
		remove: `Remove`,
		confirmMemberRemoval: `Are you sure you want to remove this member?`,
		memberRemoved: `Member has been removed.`,
		memberInvited: `Member intivation sent to `,
		memberIsInList: `The member is already in your list.`,
		memberAlreadyInvited: `The member already has an invitation.`,
		memberNotFound: `The user doesn't exists.`,
	},

	invitations: {
		productInvitations: `Product Invitations`,
		emptyInvitations: `You don´t have any invitations.`,
		ahCanEdit: `The account holder of the product might be able to view/edit your information once you accept.`,
		configureFromProfile: `You can configure this option on your profile.`,
		confirmReject: `This action can not be undone. Are you sure you want to reject the invitation?`,
		confirmAccept: `Do you want to confirm the invitation?`,
		invitationPending: `{{inviter}} invited you to a product.`,
		invitationAccepted: `You accepted {{inviter}} invitation.`,
		invitationRejected: `You rejected {{inviter}} invitation.`,
		acceptedMessage: `Invitation accepted!`,
		rejectedMessage: `Invitation rejected!`,
	},

	accessDenied: {
		heading: `Access Denied`,
		leading: `You don't have permissions to access.`,
		goBack: `Go Back`,
	},

	activeProducts: {
		activeProducts: `Order history`,
		activeProductsB2B: `{{b2bCompanyName}} Package`,
		purchaseDate: `Purchase date`,
		suscriptionEndsAt: `Suscription ends at`,
		activeSince: `Active since`,
	},

	recentActivity: {
		recentActivity: `Recent Activity`,
		noActivityForProduct: `No recent activity recorded for this product.`,
		noActivityForAccount: "Welcome to your Candoo Dashboard account!",
		dateFormatAppoint: `MM/DD/YYYY - h:mma`,
	},

	supportTicket: {
		header: `Support`,
		ticketNumber: `Ticket #{{ticket}}`,
	},

	accountSetup: {
		coupleInfo: `Your Spouse/Partner profile information`,
		coupleDevices: `Your Spouse/Partner device information`,
		relativeInfo: `Your Relative profile information`,
		relativeDevices: `Your Relative device information`,
		caregiverInfo: `Your client profile information`,
		caregiverDevices: `Your client device information`,
		myInfo: `Your information`,
		myDevices: `Your devices`,
		otherInfo: `Tell us about the person who will be using Candoo Tech`,
		otherDevices: `Device information`,
		spouseInfo: `Spouse {{ number }} information`,
		spouseDevices: `Spouse {{ number }} devices`,
	},
}
