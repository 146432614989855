/* eslint-disable react/jsx-props-no-spreading */

import React from "react"
import { Checkbox as AntdCheckbox } from "antd"
import styled from "styled-components"

import colors from "../theme/colors"

const StyledCheckbox = styled(AntdCheckbox)`
	color: ${colors.darkGrey};
	margin: 5px 20px 5px 0px;

	& + .ant-checkbox-wrapper {
		margin-left: 0;
	}

	&.ant-checkbox-wrapper:hover .ant-checkbox-inner,
	&.ant-checkbox-wrapper:hover .ant-checkbox::after,
	.ant-checkbox:hover::after,
	.ant-checkbox-checked::after,
	.ant-checkbox:hover .ant-checkbox-inner,
	.ant-checkbox-input:focus + .ant-checkbox-inner {
		border-color: ${colors.orange};
	}

	.ant-checkbox-checked .ant-checkbox-inner {
		border-color: ${colors.orange};
		background-color: ${colors.orange};
	}
`

const Checkbox = props => <StyledCheckbox {...props} />

Checkbox.Group = props => <AntdCheckbox.Group {...props} />
Checkbox.Group.displayName = "CheckboxGroup"

export default Checkbox
