export default {
	ACTIVITIES: "activities",
	AGENTS: "agents",
	APPOINTMENTS: "appointments",
	CONFIG: "config",
	PRODUCT_CATALOG: "productCatalog",
	PRODUCT_INVITES: "productInvites",
	PRODUCTS: "productOrders",
	USERS: "customers",
	UTILIZATION: "serviceUtilization",
	TICKETS: "supportTickets",
}
