import sizes from "../utils/sizes"

export default {
	mobileS: `(min-width: ${sizes.mobileS}px)`,
	mobileM: `(min-width: ${sizes.mobileM}px)`,
	mobileL: `(min-width: ${sizes.mobileL}px)`,
	tablet: `(min-width: ${sizes.tablet}px)`,
	tabletL: `(min-width: ${sizes.tabletL}px)`,
	laptop: `(min-width: ${sizes.laptop}px)`,
	laptopL: `(min-width: ${sizes.laptopL}px)`,
	desktop: `(min-width: ${sizes.desktop}px)`,
	desktopL: `(min-width: ${sizes.desktop}px)`,

	maxMobileS: `(max-width: ${sizes.mobileS - 1}px)`,
	maxMobileM: `(max-width: ${sizes.mobileM - 1}px)`,
	maxMobileL: `(max-width: ${sizes.mobileL - 1}px)`,
	maxTablet: `(max-width: ${sizes.tablet - 1}px)`,
	maxTabletL: `(max-width: ${sizes.tabletL - 1}px)`,
	maxLaptop: `(max-width: ${sizes.laptop - 1}px)`,
	maxLaptopL: `(max-width: ${sizes.laptopL - 1}px)`,
	maxDesktop: `(max-width: ${sizes.desktop - 1}px)`,
	maxDesktopL: `(max-width: ${sizes.desktop - 1}px)`,
}
