/* eslint-disable react/jsx-props-no-spreading */

import React, { useEffect } from "react"
import { Modal as AntdModal } from "antd"
import styled from "styled-components"
import PropTypes from "prop-types"

import useWindowSize from "../hooks/useWindowSize"

import { isViewportMobile } from "../utils/tools"
// import colors from "../theme/colors"
import device from "../theme/device"

const StyledModal = styled(AntdModal)`
	${({ maxWidth }) =>
		maxWidth &&
		`
			@media ${device.tablet} {
				max-width: ${maxWidth};
			}
		`}
`

const Modal = props => {
	const { width: windowWidth } = useWindowSize()
	const { width, ...rest } = props
	const { visible } = rest

	useEffect(() => {
		if (window.zE) {
			if (visible && window.zE.hide) {
				window.zE.hide()
			} else if (!visible && window.zE.show) {
				window.zE.show()
			}
		}
	}, [visible])

	// Ignoring with on mobile portview
	if (windowWidth && !isViewportMobile(windowWidth)) {
		rest.width = width
	}

	return <StyledModal {...rest} />
}

Modal.propTypes = {
	width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	visible: PropTypes.bool,
}

Modal.defaultProps = {
	width: undefined,
	visible: false,
}

export default Modal
