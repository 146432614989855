/* eslint-disable react/jsx-props-no-spreading */

import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import Button from "./Button"
import colors from "../theme/colors"

import device from "../theme/device"

const StyledAccountTypeSelector = styled("div")`
	width: 100%;
	max-width: 800px;
	margin: 10px auto 0 auto;

	.intro {
		text-align: center;
		margin: 0 0 30px 0;
		font-size: 22px;
	}

	.type-options {
		display: flex;
		justify-content: space-evenly;
		align-items: flex-start;

		.type-option {
			border: 2px solid ${colors.orange};
			padding: 10px;
			width: 180px;
			height: 120px;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			border-radius: 10px;

			&:hover,
			&:focus {
				border: 2px solid ${colors.orange};
			}

			.title {
				margin-bottom: 2px;
				font-family: "Poppins-SemiBold";
				color: "#333";
				font-size: 18px;
				white-space: normal;
			}

			.description {
				color: ${colors.grey};
				font-size: 13px;
				white-space: normal;
				margin-bottom: 0;
			}

			&.selected {
				background: ${colors.orange};

				.title,
				.description {
					color: white;
				}
			}
		}
	}
	@media ${device.maxTablet} {
		.type-options {
			flex-wrap: wrap;
			justify-content: space-around;
			.type-option {
				margin-top: 10px;
				width: 100%;
				height: auto;
				min-height: 75px;
				padding: 0 40px;
				&:first-child {
					margin-top: 0;
				}
			}
		}
	}
`

const AccountTypeSelector = ({ selected, onChange, types, isCouple }) => {
	const _renderTypeOption = option => {
		if (option.id === "couple" && !isCouple) return null
		if (option.id === "myself" && isCouple) return null
		return (
			<Button
				type="clean"
				key={`type-option-${option.id}`}
				className={`type-option ${option.id === selected ? "selected" : ""}`}
				onClick={() => {
					if (onChange && option.id !== selected) {
						onChange(option.id)
					}
				}}
			>
				<p className="title">{option.title}</p>
				<p className="description">{option.description}</p>
			</Button>
		)
	}

	return (
		<StyledAccountTypeSelector>
			<p className="intro">I need Candoo Tech for...</p>
			<div className="type-options">{types.map(_renderTypeOption)}</div>
		</StyledAccountTypeSelector>
	)
}

AccountTypeSelector.propTypes = {
	types: PropTypes.arrayOf(PropTypes.object).isRequired,
	selected: PropTypes.string,
	onChange: PropTypes.func,
	isCouple: PropTypes.bool.isRequired,
}

AccountTypeSelector.defaultProps = {
	selected: "",
	onChange: null,
}

export default AccountTypeSelector
