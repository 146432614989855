import moment from "moment"

import sizes from "./sizes"
import ErrorsMsgConstants from "../constants/ErrorsMsgConstants"

export const getViewPort = () => {
	let e = window
	let a = "inner"

	const isClient = typeof e !== "undefined"
	if (!isClient) return false

	if (!("innerWidth" in e)) {
		a = "client"
		e = document.documentElement || document.body
	}

	return { width: e[`${a}Width`] || null, height: e[`${a}Height`] || null }
}

export const isViewportMobile = width => {
	return width && width < sizes.tablet
}

export const isValidUSZip = zip => /(^\d{5}$)|(^\d{5}-\d{4}$)/.test(zip)

export const isNum = val => /^\d+$/.test(val)

export const isPhoneNum = val => /^[\d+]+$/.test(val)

export const resolveErrorMessage = (error, fallback) => {
	let msg = ErrorsMsgConstants[error] || null

	if (!msg) {
		msg = (fallback && ErrorsMsgConstants[fallback]) || "common:somethingWrong"
	}

	return msg
}

export const objectHas = Object.prototype.hasOwnProperty

export const generateURLWithParams = (path, params) => {
	if (path && params) {
		const reducer = (acc, cur) =>
			!cur || !cur.length ? acc : acc.replace(`:${cur[0]}`, cur[1])
		return Object.entries(params).reduce(reducer, path)
	}

	return ""
}

export const appointmentDateFormat = date => {
	const dateMoment = moment(date)
	return `${dateMoment.format("MMM D, YYYY")} - ${dateMoment.format("h:mma")}`
}

export const windowOpenFocus = url => {
	if (!url) {
		return null
	}

	const win = window.open(url, "_blank")
	win.focus()

	return win
}

export const smoothScrollPromise = top => {
	if (window.pageYOffset === top) {
		return true
	}

	window.scrollTo({
		top,
		behavior: "smooth",
	})

	return new Promise((resolve, reject) => {
		const scrollHandler = () => {
			const failed = setTimeout(() => {
				window.removeEventListener("scroll", scrollHandler)
				reject()
			}, 2000)

			if (window.pageYOffset === top) {
				window.removeEventListener("scroll", scrollHandler)
				clearTimeout(failed)
				resolve()
			}
		}

		window.addEventListener("scroll", scrollHandler)
	})
}

export const isCoupleProduct = product => {
	let isCouple = false
	if (product && product.productId === "5d2cae7c5590780001505342") {
		isCouple = true
	}

	if (product && product.variantOptions && product.variantOptions.length) {
		product.variantOptions.forEach(v => {
			if (v.value.indexOf("Couple") >= 0) {
				isCouple = true
			}
		})
	}
	return isCouple
}
