import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import Loader from "./Loader"

import logo from "../images/logo.png"

const StyledDiv = styled("div")`
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	.logo {
		width: 150px;
	}

	p {
		margin: 10px 0 0 0;
		font-size: 16px;
		font-family: "Poppins-SemiBold";
		text-align: center;
	}
`

const ScreenLoader = ({ text }) => {
	return (
		<StyledDiv>
			<img className="logo" src={logo} alt="Candoo Logo" />
			{text}
			<Loader />
		</StyledDiv>
	)
}

ScreenLoader.propTypes = {
	text: PropTypes.node,
}

ScreenLoader.defaultProps = {
	text: "",
}

export default ScreenLoader
