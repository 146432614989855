/* eslint-disable react/jsx-props-no-spreading */

import React from "react"
import { Button as AntdButton } from "antd"
import styled from "styled-components"

import colors from "../theme/colors"

const StyledButton = styled(AntdButton)`
	/* border-color: transparent; */

	&.ant-btn-background-ghost {
		&:hover,
		&:active,
		&:focus {
			border-color: transparent;
		}
	}

	&.ant-btn-primary {
		background-color: ${colors.orange};
		border-color: ${colors.orange};

		&[disabled],
		&[disabled]:hover {
			color: rgba(0, 0, 0, 0.25);
			background: rgba(${colors.orange_RGB}, 0.3);
			border-color: rgba(${colors.orange_RGB}, 0.3);
			text-shadow: none;
			box-shadow: none;
		}
	}

	&.ant-btn-cancel-form {
		color: ${colors.darkGrey};
		border: 1px solid ${colors.darkGrey};

		&[disabled],
		&[disabled]:hover {
			color: rgba(0, 0, 0, 0.25);
			color: ${colors.grey};
			background: white;
			border-color: ${colors.grey};
			text-shadow: none;
			box-shadow: none;
		}
	}

	&.ant-btn-button {
		border-color: ${colors.orange};
		color: ${colors.darkGrey};

		&:hover {
			background-color: ${colors.orange};
			color: white;
		}

		&[disabled],
		&[disabled]:hover {
			color: rgba(0, 0, 0, 0.25);
			background: white;
			border-color: rgba(${colors.orange_RGB}, 0.3);
			text-shadow: none;
			box-shadow: none;
		}
	}

	&.ant-btn-link:not(.ant-btn-dangerous) {
		color: ${colors.orange};
	}

	&.ant-btn-secondary {
		color: ${colors.orange};
		border: 1px solid ${colors.orange};

		&[disabled],
		&[disabled]:hover {
			color: rgba(0, 0, 0, 0.25);
			color: rgba(${colors.orange_RGB}, 0.3);
			background: white;
			border-color: rgba(${colors.orange_RGB}, 0.3);
			text-shadow: none;
			box-shadow: none;
		}
	}

	&.ant-btn-clean {
		border: none;
		background: none;
		padding: 0;
		margin: 0;
		height: auto;

		&:focus,
		&:active,
		&:hover {
			border: inherit;
			background: inherit;
			outline: none;
			color: inherit;
		}
	}

	&[ant-click-animating-without-extra-node]:after {
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}
`

const Button = props => <StyledButton {...props} />

export default Button
