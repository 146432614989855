import React, { useState, useEffect, useCallback } from "react"
import styled from "styled-components"

import { useStateContext as useCommonContext } from "../providers/common/provider"

import AccountSetupStepper from "../components/AccountSetupStepper"
import AccountSetupStep0 from "../components/AccountSetupStep0"
import AccountSetupStep1 from "../components/AccountSetupStep1"
import AccountSetupStep2 from "../components/AccountSetupStep2"

import device from "../theme/device"
import appConfig from "../config/AppConfig"

import Logo from "../images/logo.png"

import { smoothScrollPromise } from "../utils/tools"

const StyledWrapper = styled("div")`
	width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	padding: 15px 15px 20px;

	.continue-skip {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 15px;

		> button {
			margin: 0 15px;
		}
	}
`

const StyledWelcome = styled("div")`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;

	.logo {
		width: 150px;
	}

	.message {
		margin-bottom: 0;
		margin-left: 20px;
		font-size: 26px;
		font-family: "Poppins-SemiBold";
		color: black;

		span {
			font-family: "Poppins-Regular";
			font-size: 18px;
		}
	}

	@media ${device.maxTablet} {
		flex-direction: column;

		.logo {
			width: 100px;
			margin-bottom: 10px;
		}

		.message {
			font-size: 18px;
			text-align: center;

			span {
				font-size: 16px;
			}
		}
	}
`

const STEPS_CONTENT = [
	{
		title: `Account Setup`,
		subtitle: `Complete the essentials`,
		description: `Let’s start with the basics:`,
	},
	{
		title: `Profile Information`,
		subtitle: `Let’s review your technology`,
		description: `Need help? Call in and we’ll help you out`,
	},
	{
		title: `Get started`,
		subtitle: `Ready for your Candoo appointment!`,
		description: ``,
	},
]

const STEPS_COMPONENTS = [
	AccountSetupStep0,
	AccountSetupStep1,
	AccountSetupStep2,
]

const AccountSetupView = () => {
	const commonState = useCommonContext()
	const {
		accountHolder,
		additionalAgent,
		additionalAgent2,
		additionalQuestion,
		accountType,
		isCoupleProduct,
		isGiftcard,
		product,
	} = commonState

	const [currentStep, setCurrentStep] = useState(() => {
		if (product && !isGiftcard) {
			return 0
		}
		return 2
	})

	const submitData = useCallback(async () => {
		if (currentStep === 2 && product && accountHolder) {
			// call cloudfnc handle setup complete
			await fetch(
				`${appConfig.API_BASE}/postOnboardingData?accessToken=${appConfig.ACCESS_TOKEN}`,
				{
					method: "POST",
					body: JSON.stringify({
						accountHolder,
						accountType,
						additionalAgent,
						additionalAgent2,
						additionalQuestion,
						isCoupleProduct,
						product,
					}),
				}
			)
		}
	}, [
		currentStep,
		accountHolder,
		additionalAgent,
		additionalAgent2,
		additionalQuestion,
		accountType,
		isCoupleProduct,
		product,
	])

	useEffect(() => {
		submitData()
	}, [submitData])

	const _switchToStep = async step => {
		if (step && step !== currentStep) {
			await smoothScrollPromise(0)
			setCurrentStep(step)
		}
	}

	const _renderCurrentStep = () => {
		const CurrentComponent = STEPS_COMPONENTS[currentStep]

		return <CurrentComponent switchToStep={_switchToStep} />
	}

	return (
		<StyledWrapper>
			<StyledWelcome>
				<img className="logo" src={Logo} alt="Candoo Logo" />
				<p className="message">
					Thank you for your order. <br />
					<span>We need a little more information to get you set up.</span>
				</p>
			</StyledWelcome>

			<AccountSetupStepper current={currentStep} steps={STEPS_CONTENT} />
			{_renderCurrentStep()}
		</StyledWrapper>
	)
}

export default AccountSetupView
