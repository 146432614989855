import React from "react"

import SEO from "../components/Seo"
import App from "../controllers/App"

import "../i18n"

import GlobalStyle from "../theme/global-style"

const IndexPage = () => (
	<>
		<SEO title="Home" />
		<GlobalStyle/>
		<App />
	</>
)

export default IndexPage
