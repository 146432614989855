import React from "react"
import styled from "styled-components"

import { useStateContext as useCommonContext } from "../providers/common/provider"

import ContactInfo from "./ContactInfo"

import device from "../theme/device"

const StyledAllSet = styled("p")`
	font-family: "Poppins-Light";
	font-size: 28px;
	margin-top: 40px;
	max-width: 600px;
	text-align: center;

	@media ${device.maxTablet} {
		font-size: 24px;
		margin-top: 15px;
	}
`

const AccountSetupStep2 = () => {
	const { accountType, additionalAgent } = useCommonContext()
	let who = "you"
	let whose = "your"

	if (accountType === "other") {
		if (
			additionalAgent &&
			additionalAgent.firstName &&
			additionalAgent.lastName
		) {
			who = `${additionalAgent.firstName} ${additionalAgent.lastName}`
		} else {
			who = "your relative or client"
		}

		whose = "their"
	}

	return (
		<>
			<StyledAllSet>
				You&lsquo;re all set! A Candoo customer service rep will contact {who}{" "}
				to schedule {whose} next appointment.
			</StyledAllSet>
			<ContactInfo />
		</>
	)
}

export default AccountSetupStep2
