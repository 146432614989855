export default {
	mobileS: 320,
	mobileM: 375,
	mobileL: 425,
	tablet: 768,
	tabletL: 992,
	laptop: 1024,
	laptopL: 1440,
	desktop: 2560,
}
