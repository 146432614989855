import firebaseInstance from "./firebaseInstance"
import FirebaseConstants from "../constants/FirebaseConstants"

import AppointmentsConstants from "../constants/AppointmentsConstants"
import InvitationsConstants from "../constants/InvitationsConstants"
import ActivitiesConstants from "../constants/ActivitiesConstants"

import { objectHas } from "./tools"

export const createActivity = async activityData => {
	/* {
		// id: "ACTIVITY_ID",
		type: "ACTIVITY_TYPE",
		// createdAt: 1601318579598,
		customerId: "91zA1gjDk9SjpIvgsNokmAk7H7t2",
		customerName: "GastonQuuack Sch",
		appointmentId: "3OmZd2Pohkif8r4zoZGo",
		appointmentDate: 1606589012476,
		serviceType: "TYPE_SUPPORT_SESSION",
		serviceName: "Support Session",
		serviceDuration: "30’",
		agentId: "AGENT_ID",
		agentName: "AGENT_NAME",
		productId: "PRODUCT_ID",
		productName: "PRODUCT_NAME"
	} */

	await firebaseInstance.createDocument(FirebaseConstants.ACTIVITIES, {
		createdAt: Date.now(),
		...activityData,
	})
}

export const signIn = async (email, password) => {
	const { error, user } = await firebaseInstance.signInWithEmailAndPassword(
		email,
		password
	)

	if (!error) {
		const UID = user.user.uid
		await firebaseInstance.updateDocument(`${FirebaseConstants.USERS}/${UID}`, {
			lastSignInTime: Date.now(),
		})
	}

	return { error, user }
}

export const createAccount = async values => {
	const { email, password, firstName, lastName } = values
	const { error, user } = await firebaseInstance.createAccount(
		email,
		password,
		firstName
	)

	if (!error) {
		const UID = user.user.uid
		const now = Date.now()
		firebaseInstance.setDocument(FirebaseConstants.USERS, UID, {
			id: UID,
			primaryEmail: email,
			secondaryEmails: [],
			firstName,
			lastName,
			createdAt: now,
			lastSignInTime: now,
		})
	}

	return { error, user }
}

export const getUserData = async userId => {
	try {
		const id = userId || firebaseInstance.auth.currentUser.uid || null

		if (!id) {
			return { user: null, error: true }
		}

		const user = await firebaseInstance.getDocument(
			`${FirebaseConstants.USERS}/${id}`
		)

		return { user, error: null }
	} catch (error) {
		return { user: null, error }
	}
}

export const setUserOnboarded = async userId => {
	const id = userId || firebaseInstance.auth.currentUser.uid || null

	await firebaseInstance.updateDocument(`${FirebaseConstants.USERS}/${id}`, {
		onboarded: true,
	})

	return true
}

export const updateUserData = async (userId, values) => {
	try {
		const id = userId || firebaseInstance.auth.currentUser.uid || null

		if (!id) {
			return { user: null, error: true }
		}

		const newValues = {
			firstName: values.firstName,
			lastName: values.lastName,
			livingConditions: values.livingConditions,
			interests: values.interests,
			phone: values.phone,
			zip: values.zip,
			city: values.city,
			state: values.state,
			address: values.address,
			contactMethod: values.contactMethod,
			summariesMethod: values.summariesMethod,
			otherNotes: values.otherNotes,
		}

		if (objectHas.call(values, "accountHolderEdit")) {
			newValues.accountHolderEdit = values.accountHolderEdit
		}

		await firebaseInstance.updateDocument(
			`${FirebaseConstants.USERS}/${id}`,
			newValues
		)

		if (firebaseInstance.auth.currentUser.uid === id) {
			firebaseInstance.updateAuthCurrentUserProfile({
				displayName: values.firstName,
			})
		}

		return { success: true, error: null }
	} catch (error) {
		return { success: false, error }
	}
}

export const updateUserDevices = async (userId, values) => {
	try {
		const id = userId || firebaseInstance.auth.currentUser.uid || null

		if (!id) {
			return { user: null, error: true }
		}

		const {
			smartPhones,
			smartPhonesOther,
			tablet,
			tabletOther,
			laptop,
			laptopOther,
			voiceRecognition,
			voiceRecognitionOther,
			printers,
			router: routersWifi,
			homeSafetyDevices,
			cloudBackup,
			interestedInCloudBackup,
		} = values

		await firebaseInstance.updateDocument(`${FirebaseConstants.USERS}/${id}`, {
			smartPhones,
			smartPhonesOther,
			tablet,
			tabletOther,
			laptop,
			laptopOther,
			voiceRecognition,
			voiceRecognitionOther,
			printers,
			router: routersWifi,
			homeSafetyDevices,
			cloudBackup,
			interestedInCloudBackup,
		})

		return { success: true, error: null }
	} catch (error) {
		return { success: false, error }
	}
}

export const createAppointment = async (userId, userFullName, values) => {
	try {
		const id = userId || firebaseInstance.auth.currentUser.uid || null

		if (!id) {
			return { success: false, error: true }
		}

		const {
			user,
			agent,
			appointmentType,
			description,
			firstChoice,
			secondChoice,
			thirdChoice,
		} = values

		const now = Date.now()

		const appointmentId = await firebaseInstance.createDocument(
			FirebaseConstants.APPOINTMENTS,
			{
				status: AppointmentsConstants.status.PENDING,
				createdAt: now,
				createdBy: id,
				updatedAt: now,
				lastUpdatedBy: id,
				user,
				agent,
				appointmentType,
				description,
				firstChoice: (firstChoice && firstChoice.valueOf()) || null,
				secondChoice: (secondChoice && secondChoice.valueOf()) || null,
				thirdChoice: (thirdChoice && thirdChoice.valueOf()) || null,
			}
		)

		createActivity({
			type: ActivitiesConstants.types.APPOINTMENT_CREATED,
			customerId: id,
			customerName: userFullName,
			appointmentId,
			appointmentDate: (firstChoice && firstChoice.valueOf()) || null,
			serviceType: appointmentType,
			agentId: agent,
			// productId: "PRODUCT_ID"
		})

		return { success: true, error: null }
	} catch (error) {
		return { success: false, error }
	}
}

export const updateAppointment = async (userId, appointmentId, values) => {
	try {
		const id = userId || firebaseInstance.auth.currentUser.uid || null

		if (!id) {
			return { success: false, error: true }
		}

		const currentData = await firebaseInstance.getDocument(
			`${FirebaseConstants.APPOINTMENTS}/${appointmentId}`
		)

		if (
			currentData &&
			currentData.status === AppointmentsConstants.status.PENDING
		) {
			const {
				user,
				agent,
				appointmentType,
				description,
				firstChoice,
				secondChoice,
				thirdChoice,
			} = values

			await firebaseInstance.updateDocument(
				`${FirebaseConstants.APPOINTMENTS}/${appointmentId}`,
				{
					updatedAt: Date.now(),
					lastUpdatedBy: id,
					user,
					agent,
					appointmentType,
					description,
					firstChoice: (firstChoice && firstChoice.valueOf()) || null,
					secondChoice: (secondChoice && secondChoice.valueOf()) || null,
					thirdChoice: (thirdChoice && thirdChoice.valueOf()) || null,
				}
			)

			return { success: true, error: null }
		}

		return { success: false, error: "appointment-not-editable" }
	} catch (error) {
		return { success: false, error }
	}
}

export const cancelAppointment = async (appointmentId, userId) => {
	try {
		const id = userId || firebaseInstance.auth.currentUser.uid || null

		if (!id) {
			return { user: null, error: true }
		}

		await firebaseInstance.updateDocument(
			`${FirebaseConstants.APPOINTMENTS}/${appointmentId}`,
			{
				updatedAt: Date.now(),
				lastUpdatedBy: id,
				status: AppointmentsConstants.status.CANCELED,
			}
		)

		return { success: true, error: null }
	} catch (error) {
		return { success: false, error }
	}
}

export const updateInvitation = async (invitation, status) => {
	try {
		const { id, customerId, productId } = invitation

		if (status === InvitationsConstants.status.ACCEPTED) {
			const productPath = `${FirebaseConstants.PRODUCTS}/${productId}`
			const productOrder = await firebaseInstance.getDocument(productPath)

			if (productOrder && productOrder.members) {
				const members = [...productOrder.members]

				if (!members.some(m => m === customerId)) {
					members.push(customerId)
					await firebaseInstance.updateDocument(productPath, {
						members,
						updatedAt: Date.now(),
					})
				}
			} else {
				return { succes: false, error: true }
			}
		}

		await firebaseInstance.updateDocument(
			`${FirebaseConstants.PRODUCT_INVITES}/${id}`,
			{
				status,
				updatedAt: Date.now(),
			}
		)

		return { succes: true, error: false }
	} catch (error) {
		return { succes: false, error }
	}
}
