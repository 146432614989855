import firebase from "@firebase/app"

import "@firebase/auth"
import "@firebase/firestore"
import "@firebase/storage"
import FirebaseConfig from "../config/FirebaseConfig"

export default class FirebaseService {
	static __instance = null

	static instance() {
		if (!FirebaseService.__instance) {
			FirebaseService.__instance = new FirebaseService()
		}

		return typeof window !== "undefined" ? FirebaseService.__instance : null
	}

	constructor() {
		firebase.initializeApp(FirebaseConfig)

		if (typeof window !== "undefined") {
			this.auth = firebase.auth()
			this.database = firebase.firestore()
			this.storage = firebase.storage()

			this.uploadTask = null
		}
	}

	uploadFile(filePath, file, metadata, nextCb, errorCb, completeCb) {
		const storageRef = this.storage.ref()
		this.uploadTask = storageRef.child(filePath).put(file, metadata)

		return this.uploadTask.on("state_changed", nextCb, errorCb, completeCb)
	}

	cancelFileUpload() {
		if (this.uploadTask && this.uploadTask.cancel) {
			this.uploadTask.cancel()
			this.clearUploadTask()
		}

		return false
	}

	clearUploadTask() {
		if (this.uploadTask) {
			this.uploadTask = null
		}
	}

	getFileRefFromPath(filePath) {
		const storageRef = this.storage.ref()
		return storageRef.child(filePath)
	}

	getFileRefFromUrl(fileUrl) {
		return this.storage.refFromURL(fileUrl)
	}

	getCollectionRef(collection) {
		return this.database.collection(collection)
	}

	getDocumentRef(path) {
		return this.database.doc(path)
	}

	getQuery(collection, queries) {
		const collectionRef = this.getCollectionRef(collection)

		let query

		for (let i = 0; i < queries.length; i += 1) {
			const { field, operator, value } = queries[i]
			query =
				i === 0
					? collectionRef.where(field, operator, value)
					: query.where(field, operator, value)
		}

		return query
	}

	async getCollection(collection) {
		const collectionRef = this.getCollectionRef(collection)
		const collectionSnapshot = await collectionRef.get()
		const collectionDocs = collectionSnapshot.docs

		/* if (collectionDocs.length > 1) {
			const collectionObj = {}

			for (let i = 0; i < collectionDocs.length; i += 1) {
				collectionObj[collectionDocs[i].id] = Object.values(
					collectionDocs[i].data()
				)
			}

			return collectionObj
		} */

		return collectionDocs.map(doc => doc.data())
	}

	async getDocument(path) {
		const documentRef = this.getDocumentRef(path)
		const documentSnapshot = await documentRef.get()

		return documentSnapshot.exists ? documentSnapshot.data() : null
	}

	async getDocuments(collection, query) {
		const documentsSnapshots = await this.getQuery(collection, query).get()

		return documentsSnapshots.docs.map(doc => ({
			id: doc.id,
			...doc.data(),
		}))
	}

	async getDocumentById(collection, id) {
		return this.getDocument(`${collection}/${id}`)
	}

	async getDocumentsByIds(collection, ids) {
		const documentsPromises = ids.map(id =>
			this.getDocument(`${collection}/${id}`)
		)
		const documentsSnapshots = await Promise.all(documentsPromises)

		return documentsSnapshots.filter(docSnapshot => docSnapshot)
	}

	async getCollectionsDocumentsByIds(collections, ids) {
		const paths = []

		ids.forEach(id => {
			collections.forEach(collection => {
				paths.push({ collection, id })
			})
		})

		const documentsPromises = paths.map(path => {
			const { collection, id } = path

			if (collection === "compositions") {
				return this.getDocument(`${collection}/${id}`)
			}

			const queries = [
				{
					field: "compositionId",
					operator: "==",
					value: id,
				},
			]

			const documents = this.getDocuments(collection, queries)

			return documents
		})

		const documentsSnapshots = await Promise.all(documentsPromises)
		const documents = []

		documentsSnapshots.forEach(document => {
			if (Array.isArray(document)) {
				documents.push(...document)
			} else {
				documents.push(document)
			}
		})

		return documents.filter(docSnapshot => docSnapshot)
	}

	async updateDocument(path, newProperties) {
		const documentRef = this.getDocumentRef(path)
		return documentRef.set(newProperties, { merge: true })
	}

	async setDocument(collection, docId, data) {
		const docRef = this.getCollectionRef(collection).doc(docId)

		return docRef.set({ ...data })
	}

	async createDocument(collection, data, cid) {
		let docRef
		if (cid) {
			docRef = this.getCollectionRef(collection).doc(cid)
		} else {
			docRef = this.getCollectionRef(collection).doc()
		}
		await docRef.set({ ...data, id: docRef.id })

		return docRef.id || null
	}

	async deleteDocument(path) {
		const documentRef = this.getDocumentRef(path)
		const documentSnapshot = await documentRef.get()

		if (documentSnapshot.exists) {
			await documentRef.delete()
			return true
		}

		return false
	}

	async getFileUrl(filePath) {
		const fileRef = this.getFileRefFromPath(filePath)
		let fileUrl = null

		if (fileRef) {
			fileUrl = await fileRef.getDownloadURL()
		}

		return fileUrl
	}

	async deleteFile(file) {
		let fileRef = null

		if (file.filePath) {
			fileRef = this.getFileRefFromPath(file.filePath)
		} else if (file.fileUrl) {
			fileRef = this.getFileRefFromUrl(file.fileUrl)
		}

		if (!fileRef) {
			return { error: "No file reference." }
		}

		return fileRef
			.delete()
			.then(() => ({ error: false }))
			.catch(error => ({ error }))
	}

	setAuthObserver(callback) {
		return this.auth.onAuthStateChanged(user => {
			callback(user)
		})
	}

	signInWithEmailAndPassword(email, password) {
		return this.auth
			.signInWithEmailAndPassword(email, password)
			.then(user => ({ error: null, user }))
			.catch(error => ({ error: error.code, user: null }))
	}

	sendVerificationEmail() {
		const user = this.auth.currentUser
		return user.sendEmailVerification()
	}

	resetPassword(email) {
		return this.auth
			.sendPasswordResetEmail(email)
			.then(() => ({ error: null, success: true }))
			.catch(error => ({ error: error.code, success: false }))
	}

	createAccount(email, password, firstName) {
		return this.auth
			.createUserWithEmailAndPassword(email, password)
			.then(async user => {
				await this.auth.currentUser.updateProfile({ displayName: firstName })
				return { error: null, user }
			})
			.catch(error => ({ error: error.code, user: null }))
	}

	updateAuthCurrentUserProfile(newProps) {
		return this.auth.currentUser
			.updateProfile(newProps)
			.then(async user => {
				return { error: null, user }
			})
			.catch(error => ({ error: error.code }))
	}

	signOut() {
		return this.auth
			.signOut()
			.then(() => ({ success: true, error: null }))
			.catch(error => ({ success: false, error }))
	}

	setDatabaseObserver(path, callback) {
		const pathRef = this.getDocumentRef(path)

		return pathRef.onSnapshot(
			snapshot => {
				callback(snapshot)
			},
			() => {
				// console.log("setDatabaseQueryObserver error", error)
			}
		)
	}

	setDatabaseQueryObserver(collection, queries, callback) {
		return this.getQuery(collection, queries).onSnapshot(
			querySnapshot => {
				callback(querySnapshot)
			},
			() => {
				// console.log("setDatabaseQueryObserver error", error)
			}
		)
	}

	updateUserPassword(newPassword) {
		const user = this.auth.currentUser

		return user
			.updatePassword(newPassword)
			.then(() => ({ success: true, error: null }))
			.catch(error => ({ success: false, error }))
	}

	reauthenticateUser(email, password) {
		const user = this.auth.currentUser
		const credential = firebase.auth.EmailAuthProvider.credential(
			email,
			password
		)

		return user
			.reauthenticateAndRetrieveDataWithCredential(credential)
			.then(() => ({ success: true, error: null }))
			.catch(error => ({ success: false, error }))
	}
}
