import React from "react"
import styled from "styled-components"
import { Steps } from "antd"
import PropTypes from "prop-types"

import colors from "../theme/colors"
import device from "../theme/device"

const { Step } = Steps

const StyledContainer = styled("div")`
	width: 100%;
	max-width: 900px;
	margin-top: 30px;
	margin-bottom: 10px;
`

const StyledSteps = styled(Steps)`
	.ant-steps-item-finish
		.ant-steps-item-icon
		> .ant-steps-icon
		.ant-steps-icon-dot,
	.ant-steps-item-process
		.ant-steps-item-icon
		> .ant-steps-icon
		.ant-steps-icon-dot,
	.ant-steps-item-wait
		.ant-steps-item-icon
		> .ant-steps-icon
		.ant-steps-icon-dot {
		height: 20px;
		width: 20px;
		left: -5px;
		top: -5px;
	}
	.ant-steps-item-finish
		.ant-steps-item-icon
		> .ant-steps-icon
		.ant-steps-icon-dot {
		background: ${colors.orange};
	}
	.ant-steps-item-process
		.ant-steps-item-icon
		> .ant-steps-icon
		.ant-steps-icon-dot {
		border: 2px solid ${colors.orange};
		background: white;
	}
	.ant-steps-item-wait
		.ant-steps-item-icon
		> .ant-steps-icon
		.ant-steps-icon-dot {
		border: 3px solid #f0f0f0;
		background: white;
	}
	.ant-steps-item-finish
		> .ant-steps-item-container
		> .ant-steps-item-tail::after {
		background-color: ${colors.orange};
	}

	&.ant-steps-label-vertical
		.ant-steps-item:last-of-type
		.ant-steps-item-content {
		margin-right: 40px;
	}

	@media ${device.tablet} {
		&.ant-steps-label-vertical
			.ant-steps-item:last-of-type
			.ant-steps-item-content {
			margin-right: 70px;
		}
	}
`

const StyledTitlesContainer = styled("div")`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: top;
	margin-bottom: 20px;

	@media ${device.maxTablet} {
		justify-content: center;
	}
`

const StyledTitle = styled("div")`
	text-align: center;
	color: black;

	.title {
		margin-bottom: 0;
		font-size: 22px;
		font-family: "Poppins-SemiBold";
	}

	.subtitle {
		margin-bottom: 0;
		font-size: 12px;
		font-family: "Poppins-SemiBold";
		color: ${colors.grey};
	}

	&.future .title {
		color: ${colors.grey};
	}

	&.current {
		.subtitle {
			color: ${colors.darkGrey};
		}
	}

	@media ${device.maxTablet} {
		.title {
			font-size: 18px;
		}

		&.future,
		&.past {
			display: none;
		}
	}
`

const AccountSetupStepper = ({ current, steps }) => {
	const resolveClassStatus = index => {
		if (index > current) {
			return "future"
		}

		if (index < current) {
			return "past"
		}

		return "current"
	}

	return (
		<StyledContainer>
			<StyledTitlesContainer>
				{steps.map((step, index) => (
					<StyledTitle
						key={`step-title-${index}`}
						className={resolveClassStatus(index)}
					>
						<p className="title">{step.title}</p>
						<p className="subtitle">{step.subtitle}</p>
					</StyledTitle>
				))}
			</StyledTitlesContainer>
			<StyledSteps progressDot current={current}>
				{steps.map((step, index) => (
					<Step key={`step-dot-${index}`} />
				))}
			</StyledSteps>
		</StyledContainer>
	)
}

AccountSetupStepper.defaultProps = {
	current: 0,
	steps: [],
}

AccountSetupStepper.propTypes = {
	current: PropTypes.number,
	steps: PropTypes.arrayOf(PropTypes.object),
}

export default AccountSetupStepper
