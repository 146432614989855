export const Params = {
	ORDER_ID: `orderId`,
	MEMBER_ID: `memberId`,
	PRODUCT_ID: `productId`,
}

export const QueryParams = {
	ORDER_ID: `orderId`,
	CUSTOMER_EMAIL: `customerEmailAddress`,
}

export const Routes = {
	SIGNIN: `/signin`,
	REGISTER: `/register`,
	NEW_ORDER: `/new_order`,
	ACCOUNT_OVERVIEW: `/account-overview`,
	ACTIVE_PRODUCTS: `/active-products`,
	PLAN_MEMBERS: `/plan-members`,
	APPOINTMENTS: `/appointments`,
	TALK_SUPPORT: `/talk-support`,
	PROFILE: `/profile`,
	MEMBER_PROFILE: `/member`,
	PRODUCT_MEMBERS: `/members`,
	ACCESS_DENIED: `/access-denied`,
	ONBOARDING: `/onboarding`,
	ACCOUNT_SETUP: `/account-setup`,
	NOT_FOUND: `/not-found`,
}

export const Paths = {
	SIGNIN: `${Routes.SIGNIN}`,
	REGISTER: `${Routes.REGISTER}`,
	NEW_ORDER: `${Routes.NEW_ORDER}/:${Params.ORDER_ID}`,
	ACCOUNT_OVERVIEW: `${Routes.ACCOUNT_OVERVIEW}`,
	ACTIVE_PRODUCTS: `${Routes.ACTIVE_PRODUCTS}`,
	ACTIVE_PRODUCT: `${Routes.ACTIVE_PRODUCTS}/:${Params.PRODUCT_ID}`,
	PRODUCT_MEMBERS: `${Routes.ACTIVE_PRODUCTS}/:${Params.PRODUCT_ID}${Routes.PRODUCT_MEMBERS}`,
	PLAN_MEMBERS: `${Routes.PLAN_MEMBERS}`,
	APPOINTMENTS: `${Routes.APPOINTMENTS}`,
	TALK_SUPPORT: `${Routes.TALK_SUPPORT}`,
	PROFILE: `${Routes.PROFILE}`,
	MEMBER_PROFILE: `${Routes.MEMBER_PROFILE}/:${Params.MEMBER_ID}`,
	ACCESS_DENIED: `${Routes.ACCESS_DENIED}`,
	ONBOARDING: `${Routes.ONBOARDING}`,
	ACCOUNT_SETUP: `${Routes.ACCOUNT_SETUP}`,
	NOT_FOUND: `${Routes.NOT_FOUND}`,
}
