import i18n from "i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import { initReactI18next } from "react-i18next"
import en from "./en"
// import es from "./es"

i18n
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		// we init with resources
		resources: {
			en,
			// es,
		},
		fallbackLng: "en",
		debug: true,

		interpolation: {
			escapeValue: false,
		},
	})

export default i18n
