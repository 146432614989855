import { createGlobalStyle, css } from "styled-components"

const fontsCss = css`
	${import("./fonts.css")}
`

const GlobalStyle = createGlobalStyle`
	html {
		height: 100%;
	}
	
	body {
		height: auto;
		min-height: 100%;
		font-family: 'Poppins-Regular', sans-serif;
	}

	${fontsCss}
`

export default GlobalStyle
