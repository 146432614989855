export default [
	{
		long: `Alabama`,
		short: `AL`,
	},
	{
		long: `Alaska`,
		short: `AK`,
	},
	{
		long: `Arizona`,
		short: `AZ`,
	},
	{
		long: `Arkansas`,
		short: `AR`,
	},
	{
		long: `California`,
		short: `CA`,
	},
	{
		long: `Colorado`,
		short: `CO`,
	},
	{
		long: `Connecticut`,
		short: `CT`,
	},
	{
		long: `Delaware`,
		short: `DE`,
	},
	{
		long: `Florida`,
		short: `FL`,
	},
	{
		long: `Georgia`,
		short: `GA`,
	},
	{
		long: `Hawaii`,
		short: `HI`,
	},
	{
		long: `Idaho`,
		short: `ID`,
	},
	{
		long: `Illinois`,
		short: `IL`,
	},
	{
		long: `Indiana`,
		short: `IN`,
	},
	{
		long: `Iowa`,
		short: `IA`,
	},
	{
		long: `Kansas`,
		short: `KS`,
	},
	{
		long: `Kentucky`,
		short: `KY`,
	},
	{
		long: `Louisiana`,
		short: `LA`,
	},
	{
		long: `Maine`,
		short: `ME`,
	},
	{
		long: `Maryland`,
		short: `MD`,
	},
	{
		long: `Massachusetts`,
		short: `MA`,
	},
	{
		long: `Michigan`,
		short: `MI`,
	},
	{
		long: `Minnesota`,
		short: `MN`,
	},
	{
		long: `Mississippi`,
		short: `MS`,
	},
	{
		long: `Missouri`,
		short: `MO`,
	},
	{
		long: `Montana`,
		short: `MT`,
	},
	{
		long: `Nebraska`,
		short: `NE`,
	},
	{
		long: `Nevada`,
		short: `NV`,
	},
	{
		long: `New Hampshire`,
		short: `NH`,
	},
	{
		long: `New Jersey`,
		short: `NJ`,
	},
	{
		long: `New Mexico`,
		short: `NM`,
	},
	{
		long: `New York`,
		short: `NY`,
	},
	{
		long: `North Carolina`,
		short: `NC`,
	},
	{
		long: `North Dakota`,
		short: `ND`,
	},
	{
		long: `Ohio`,
		short: `OH`,
	},
	{
		long: `Oklahoma`,
		short: `OK`,
	},
	{
		long: `Oregon`,
		short: `OR`,
	},
	{
		long: `Pennsylvania`,
		short: `PA`,
	},
	{
		long: `Rhode Island`,
		short: `RI`,
	},
	{
		long: `South Carolina`,
		short: `SC`,
	},
	{
		long: `South Dakota`,
		short: `SD`,
	},
	{
		long: `Tennessee`,
		short: `TN`,
	},
	{
		long: `Texas`,
		short: `TX`,
	},
	{
		long: `Utah`,
		short: `UT`,
	},
	{
		long: `Vermont`,
		short: `VT`,
	},
	{
		long: `Virginia`,
		short: `VA`,
	},
	{
		long: `Washington`,
		short: `WA`,
	},
	{
		long: `West Virginia`,
		short: `WV`,
	},
	{
		long: `Wisconsin`,
		short: `WI`,
	},
	{
		long: `Wyoming`,
		short: `WY`,
	},
]
