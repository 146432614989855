import React from "react"
import { Router } from "@reach/router"

import NotFoundView from "./NotFoundView"
import OnboardingV2View from "./OnboardingV2View"
import AccountSetupV2View from "./AccountSetupV2View"

import { Provider as CommonProvider } from "../providers/common/provider"

import { Paths } from "../constants/RouterConstants"

import "antd/dist/antd.css"

const App = () => (
	<>
		<CommonProvider>
			<Router>
				<OnboardingV2View path={Paths.ONBOARDING} />
				<AccountSetupV2View path={Paths.ACCOUNT_SETUP} />
				<NotFoundView path={Paths.NOT_FOUND} default />
			</Router>
		</CommonProvider>
	</>
)

export default App
