import { useState, useEffect } from "react"

import { getViewPort } from "../utils/tools"

export default function useWindowSize() {
	const [windowSize, setWindowSize] = useState(false)

	useEffect(() => {
		function handleResize() {
			setWindowSize(getViewPort())
		}

		if (!windowSize) handleResize()

		window.addEventListener("resize", handleResize)
		return () => {
			window.removeEventListener("resize", handleResize)
		}
	}, [windowSize]) // This should run on every render

	return windowSize
}
