import React from "react"
import { LoadingOutlined } from "@ant-design/icons"

import colors from "../theme/colors"

const Loader = () => (
	<LoadingOutlined style={{ fontSize: "20px", color: colors.orange }} />
)

export default Loader
