import React, { createContext, useContext, useReducer } from "react"
import PropTypes from "prop-types"

import { initialState, reducer } from "./reducer"

const StateContext = createContext()
const DispatchContext = createContext()

const useStateContext = () => {
	const context = useContext(StateContext)
	if (context === undefined) {
		throw new Error(
			"provider:common:: useStateContext must be used within a provider"
		)
	}
	return context
}

const useDispatchContext = () => {
	const context = useContext(DispatchContext)
	if (context === undefined) {
		throw new Error(
			"provider:common:: useDispatchContext must be used within a provider"
		)
	}
	return context
}

const Provider = ({ children }) => {
	const [state, dispatch] = useReducer(reducer, initialState)

	return (
		<StateContext.Provider value={state}>
			<DispatchContext.Provider value={dispatch}>
				{children}
			</DispatchContext.Provider>
		</StateContext.Provider>
	)
}

Provider.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]).isRequired,
}

export { Provider, useStateContext, useDispatchContext }
