import React, { useState, useRef } from "react"
import styled from "styled-components"
import { Collapse as AntdCollapse } from "antd"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

import {
	useStateContext as useCommonContext,
	useDispatchContext as useCommonDispatch,
} from "../providers/common/provider"
import CommonActions from "../providers/common/actions"

import UserInfoForm from "./UserInfoForm"
import UserDevicesForm from "./UserDevicesForm"
import Button from "./Button"
import Collapse from "./Collapse"

import { smoothScrollPromise } from "../utils/tools"

const { Panel } = AntdCollapse

const StyledStepWrapper = styled("div")`
	width: 100%;
	.continue-skip {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		margin-top: 15px;

		> button {
			border-radius: 10px;
			margin: 0 15px;
		}
	}

	.terms-and-conditions {
		width: 100%;
		text-align: center;
		margin: 25px auto;
		background: #f1f1f1;
		padding: 10px;
		max-width: 700px;
	}
`

const StyledHeadline = styled("p")`
	text-align: center;
`

const StyledSkipButton = styled(Button)`
	font-family: "Poppins-SemiBold";
	color: "#333";
	border: none;
`

const StyledContinueButton = styled(Button)`
	width: 150px;
	margin-top: 30px;
`

const AccountSetupStep1 = ({ switchToStep }) => {
	const { t } = useTranslation()
	const commonDispatch = useCommonDispatch()
	const {
		accountHolder,
		additionalAgent,
		additionalAgent2,
		accountType,
		isNewCustomer,
		isInstallationAndSetup,
	} = useCommonContext()

	const needAccountHolder =
		isNewCustomer && (accountType === "couple" || accountType === "myself")
	const needAdditional = additionalAgent ? !additionalAgent.id : false
	const needAdditional2 = additionalAgent2 ? !additionalAgent2.id : false

	const [subStep, setSubStep] = useState(0)
	const [infoCollapseActive, setInfoCollapseActive] = useState([
		needAccountHolder ? "AH" : "AA",
	])
	const [devicesCollapseActive, setDevicesCollapseActive] = useState([
		needAccountHolder ? "AH" : "AA",
	])
	const accountHolderInfoFormRef = useRef()
	const additionalInfoFormRef = useRef()
	const additional2InfoFormRef = useRef()
	const accountHolderDevicesFormRef = useRef()
	const additionalDevicesFormRef = useRef()
	const additional2DevicesFormRef = useRef()

	const _validateFormFields = async ref => {
		if (ref && ref.current && ref.current.validateFields) {
			const validation = await ref.current.validateFields()
			return validation
		}

		return null
	}

	const _validateForms = async ({ refAH, refAA, refAA2 }) => {
		const validation = {
			invalid: false,
			accountHolderData: null,
			additionalAgentData: null,
			additionalAgent2Data: null,
		}

		if (needAccountHolder && refAH) {
			const formAH = await _validateFormFields(refAH)

			if (formAH.valid) {
				validation.accountHolderData = { ...formAH.values }
			} else {
				validation.invalid = "AH"
			}
		}

		if (needAdditional && refAA) {
			const formAA = await _validateFormFields(refAA)

			if (formAA.valid) {
				validation.additionalAgentData = { ...formAA.values }
			} else {
				validation.invalid = "AA"
			}
		}

		if (needAdditional2 && refAA2) {
			const formAA2 = await _validateFormFields(refAA2)

			if (formAA2.valid) {
				validation.additionalAgent2Data = { ...formAA2.values }
			} else {
				validation.invalid = "AA2"
			}
		}

		return validation
	}

	const _dispatchFromValidations = validations => {
		const commonPayload = {}

		console.log("_dispatchFromValidations::", validations)

		if (validations.accountHolderData) {
			commonPayload.accountHolder = {
				...accountHolder,
				...validations.accountHolderData,
			}
		}

		if (validations.additionalAgentData) {
			commonPayload.additionalAgent = {
				...additionalAgent,
				...validations.additionalAgentData,
			}
		}

		if (validations.additionalAgent2Data) {
			commonPayload.additionalAgent2 = {
				...additionalAgent2,
				...validations.additionalAgent2Data,
			}
		}

		commonDispatch({
			type: CommonActions.SET_COMMON_PAYLOAD,
			payload: commonPayload,
		})
	}

	const _handleContinue = async () => {
		if (subStep === 0) {
			const infoValidations = await _validateForms({
				refAH: accountHolderInfoFormRef,
				refAA: additionalInfoFormRef,
				refAA2: additional2InfoFormRef,
			})

			if (!infoValidations.invalid) {
				_dispatchFromValidations(infoValidations)
				await smoothScrollPromise(0)
				if (!isInstallationAndSetup) {
					setSubStep(1)
				} else {
					switchToStep(2)
				}
			} else if (infoCollapseActive.indexOf(infoValidations.invalid) < 0) {
				setInfoCollapseActive([...infoCollapseActive, infoValidations.invalid])
				window.alert("Please complete all required fields.")
			}
		} else {
			const devicesValidations = await _validateForms({
				refAH: accountHolderDevicesFormRef,
				refAA: additionalDevicesFormRef,
				refAA2: additional2DevicesFormRef,
			})

			if (!devicesValidations.invalid) {
				_dispatchFromValidations(devicesValidations)
				switchToStep(2)
			} else if (
				devicesCollapseActive.indexOf(devicesValidations.invalid) < 0
			) {
				setDevicesCollapseActive([
					...devicesCollapseActive,
					devicesValidations.invalid,
				])
			}
		}
	}

	const _renderInfoForms = () => {
		return (
			<Collapse
				activeKey={infoCollapseActive}
				onChange={key => setInfoCollapseActive(key)}
			>
				{needAccountHolder ? (
					<Panel forceRender key="AH" header={t("accountSetup:myInfo")}>
						<UserInfoForm
							ref={accountHolderInfoFormRef}
							formName="userForm"
							saveOnFinish={false}
							userData={accountHolder}
							hideActionButtons
						/>
					</Panel>
				) : null}

				{needAdditional ? (
					<Panel
						forceRender
						key="AA"
						header={
							needAdditional2
								? t(`accountSetup:spouseInfo`, { number: 1 })
								: t(`accountSetup:${accountType}Info`)
						}
					>
						<UserInfoForm
							ref={additionalInfoFormRef}
							formName="additionalAgentForm"
							saveOnFinish={false}
							hideActionButtons
							userData={additionalAgent}
						/>
					</Panel>
				) : null}

				{needAdditional2 ? (
					<Panel
						forceRender
						key="AA2"
						header={t(`accountSetup:spouseInfo`, { number: 2 })}
					>
						<UserInfoForm
							ref={additional2InfoFormRef}
							formName="additionalAgent2Form"
							saveOnFinish={false}
							hideActionButtons
							userData={additionalAgent2}
						/>
					</Panel>
				) : null}
			</Collapse>
		)
	}

	const _renderDevicesForms = () => {
		return (
			<Collapse
				activeKey={devicesCollapseActive}
				onChange={key => setDevicesCollapseActive(key)}
			>
				{needAccountHolder ? (
					<Panel forceRender key="AH" header={t("accountSetup:myDevices")}>
						<UserDevicesForm
							ref={accountHolderDevicesFormRef}
							formName="userDevicesForm"
							saveOnFinish={false}
							userData={accountHolder}
							hideActionButtons
						/>
					</Panel>
				) : null}

				{needAdditional ? (
					<Panel
						forceRender
						key="AA"
						header={
							needAdditional2
								? t(`accountSetup:spouseDevices`, { number: 1 })
								: t(`accountSetup:${accountType}Devices`)
						}
					>
						<UserDevicesForm
							ref={additionalDevicesFormRef}
							formName="additionalAgentDevicesForm"
							saveOnFinish={false}
							hideActionButtons
							userData={additionalAgent}
						/>
					</Panel>
				) : null}

				{needAdditional2 ? (
					<Panel
						forceRender
						key="AA2"
						header={t(`accountSetup:spouseDevices`, { number: 2 })}
					>
						<UserDevicesForm
							ref={additional2DevicesFormRef}
							formName="additionalAgent2DevicesForm"
							saveOnFinish={false}
							hideActionButtons
							userData={additionalAgent2}
						/>
					</Panel>
				) : null}
			</Collapse>
		)
	}

	let who = null

	if (needAccountHolder) {
		who = "you"
	}

	if (needAdditional && needAdditional2) {
		who = "the couple"
	} else if (needAdditional) {
		if (who) {
			who += " and your "
		} else {
			who = "your "
		}

		if (accountType === "couple") {
			who += "couple"
		} else {
			who += "relative or client"
		}
	}

	return (
		<StyledStepWrapper>
			{subStep === 1 ? (
				<StyledHeadline>
					Please tell us about the technology that {who} have
				</StyledHeadline>
			) : null}
			{subStep === 0 ? _renderInfoForms() : _renderDevicesForms()}
			{subStep === 0 && (
				<p className="terms-and-conditions">
					By clicking Continue, you&lsquo;re accepting Candoo&lsquo;s
					<a
						style={{ marginLeft: 5 }}
						href="https://www.candootech.com/terms"
						rel="noreferrer"
						target="_blank"
					>
						terms and conditions.
					</a>
				</p>
			)}
			<div className="continue-skip">
				{subStep === 1 ? (
					<StyledSkipButton onClick={() => switchToStep(2)}>
						Skip this step
					</StyledSkipButton>
				) : null}
				<StyledContinueButton type="primary" onClick={_handleContinue}>
					Continue
				</StyledContinueButton>
			</div>
		</StyledStepWrapper>
	)
}

AccountSetupStep1.propTypes = {
	switchToStep: PropTypes.func.isRequired,
}

export default AccountSetupStep1
