import React, { useState } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import {
	useStateContext as useCommonContext,
	useDispatchContext as useCommonDispatch,
} from "../providers/common/provider"
import CommonActions from "../providers/common/actions"

import AccountTypeSelector from "./AccountTypeSelector"
import Button from "./Button"
import Radio from "./Radio"

import colors from "../theme/colors"
import device from "../theme/device"

/*
const StyledDescription = styled("p")`
	color: black;
	font-size: 16px;
	font-family: "Poppins-SemiBold";
	margin: 20px 0;
	white-space: pre-line;
`
*/

const StyledContinueButton = styled(Button)`
	width: 150px;
	margin-top: 40px;
	border-radius: 10px;
`

const StyledAdditionalField = styled("div")`
	margin-top: 40px;
	text-align: left;
	width: 100%;
	max-width: 800px;

	.select-agent-text {
		font-family: "Poppins-SemiBold";
		font-size: 16px;
	}

	@media ${device.maxTablet} {
		font-size: 14px;
	}
`

const StyledRadio = styled(Radio)`
	display: block;
	padding: 15px;

	&:not(:last-child) {
		border-bottom: 1px solid ${colors.lightGrey};
	}

	> span:not(.ant-radio) {
		line-height: 24px;
		height: 24px;
		display: inline-block;
		vertical-align: top;
		white-space: break-spaces;
	}

	.agent-name {
		font-size: 14px;
	}

	.agent-email {
		padding-left: 10px;
		font-size: 12px;
		color: ${colors.grey};
	}

	.ant-radio {
		padding-right: 10px;

		&:after {
			-webkit-animation: none !important;
			-moz-animation: none !important;
			-o-animation: none !important;
			-ms-animation: none !important;
			animation: none !important;
			border: none;
		}

		.ant-radio-inner {
			width: 24px;
			height: 24px;

			&:after {
				width: 14px;
				height: 14px;
				left: 4px;
				top: 4px;
			}
		}
	}

	@media ${device.maxTablet} {
		.agent-name {
			font-size: 12px;
		}

		.agent-email {
			padding-left: 10px;
			font-size: 12px;
			color: ${colors.grey};
		}
	}
`

const ACCOUNT_TYPES_OPTIONS = [
	{
		id: `myself`,
		title: `Myself`,
		description: ``,
		showCareReceivers: false,
	},
	{
		id: `couple`,
		title: `My spouse and me`,
		description: ``,
		showCareReceivers: false,
	},
	{
		id: `other`,
		title: `Someone else`,
		description: `My relatives, friends or clients.`,
		showCareReceivers: true,
	},
]

const AccountSetupStep0 = ({ switchToStep }) => {
	const {
		accountHolder,
		carereceivers,
		isNewCustomer,
		hasCouple,
		isCoupleProduct,
	} = useCommonContext()
	const commonDispatch = useCommonDispatch()
	const [agentSelected, setAgentSelected] = useState("new")
	const [additionalAnswer, setAdditionalAnswer] = useState(null)

	const [accountType, setAccountType] = useState(
		isCoupleProduct ? ACCOUNT_TYPES_OPTIONS[1].id : ACCOUNT_TYPES_OPTIONS[0].id
	)

	const _handleContinue = async () => {
		const commonPayload = {
			accountType,
			accountHolder: { ...accountHolder },
			additionalAgent: null,
			additionalAgent2: null,
			additionalQuestion: additionalAnswer,
		}

		if (isCoupleProduct) {
			if (accountType === ACCOUNT_TYPES_OPTIONS[1].id && !hasCouple) {
				commonPayload.additionalAgent = {
					address: accountHolder.address,
					state: accountHolder.state,
					city: accountHolder.city,
					zip: accountHolder.zip,
				}
			} else if (accountType === ACCOUNT_TYPES_OPTIONS[2].id) {
				commonPayload.additionalAgent = {}
				commonPayload.additionalAgent2 = {}
			}
		} else if (accountType === ACCOUNT_TYPES_OPTIONS[2].id) {
			commonPayload.additionalAgent =
				(carereceivers && carereceivers.find(c => c.id === agentSelected)) || {}
		}

		commonDispatch({
			type: CommonActions.SET_COMMON_PAYLOAD,
			payload: commonPayload,
		})

		if (
			(accountType === ACCOUNT_TYPES_OPTIONS[0].id && isNewCustomer) ||
			(commonPayload.additionalAgent && !commonPayload.additionalAgent.id) ||
			(commonPayload.additionalAgent2 && !commonPayload.additionalAgent2.id)
		) {
			switchToStep(1)
		} else {
			switchToStep(2)
		}
	}

	const _agentOption = (value, name, email) => (
		<StyledRadio key={`agent-radio-${value}`} value={value}>
			<span className="agent-name">{name}</span>
			{email ? <span className="agent-email"> ({email})</span> : null}
		</StyledRadio>
	)

	const _additionalQuestions = () => (
		<StyledAdditionalField>
			<p className="select-agent-text">Tell us what you need help with:</p>
			<Radio.Group
				name="additionalAgent"
				value={additionalAnswer}
				onChange={e => setAdditionalAnswer(e.target.value)}
			>
				<StyledRadio value="Something is not working">
					<span className="agent-name">Something is not working</span>
				</StyledRadio>
				<StyledRadio value="I need help setting something up ">
					<span className="agent-name">
						I need help setting something up or installing something
					</span>
				</StyledRadio>
				<StyledRadio value="installing something, I want to learn something new">
					<span className="agent-name">I want to learn something new</span>
				</StyledRadio>
				<StyledRadio value="Other">
					<span className="agent-name">Other</span>
				</StyledRadio>
			</Radio.Group>
		</StyledAdditionalField>
	)

	const _additionalAgents = () => {
		return (
			<StyledAdditionalField>
				<p className="select-agent-text">This order is for:</p>
				<Radio.Group
					name="additionalAgent"
					value={agentSelected}
					onChange={e => setAgentSelected(e.target.value)}
				>
					{_agentOption("new", "A new user")}
					{carereceivers
						? carereceivers.map(c =>
								_agentOption(
									c.id,
									`${c.firstName} ${c.lastName}`,
									c.primaryEmail
								)
						  )
						: null}
				</Radio.Group>
			</StyledAdditionalField>
		)
	}

	return (
		<>
			{/*
				<StyledDescription>Let’s start with the basics:</StyledDescription>
				*/}
			<AccountTypeSelector
				types={ACCOUNT_TYPES_OPTIONS}
				selected={accountType}
				isCouple={isCoupleProduct}
				onChange={selected => setAccountType(selected)}
			/>
			{_additionalQuestions()}
			{!isCoupleProduct &&
			ACCOUNT_TYPES_OPTIONS.find(ato => ato.id === accountType)
				.showCareReceivers
				? _additionalAgents()
				: null}
			<StyledContinueButton type="primary" onClick={_handleContinue}>
				Continue
			</StyledContinueButton>
		</>
	)
}

AccountSetupStep0.propTypes = {
	switchToStep: PropTypes.func.isRequired,
}

export default AccountSetupStep0
