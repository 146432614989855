/* eslint-disable react/jsx-props-no-spreading */

import React from "react"
import { Radio as AntdRadio } from "antd"
import styled from "styled-components"

import colors from "../theme/colors"
/* 
const radioStyles = css`
	
`
const StyledRadioGroup = styled(AntdRadio.Group)`
	${radioStyles}
`
*/

const StyledRadio = styled(AntdRadio)`
	color: ${colors.darkGrey};

	&.ant-radio-wrapper:hover .ant-radio,
	.ant-radio:hover .ant-radio-inner,
	.ant-radio-input:focus + .ant-radio-inner {
		border-color: ${colors.orange};
	}

	.ant-radio-checked .ant-radio-inner {
		border-color: ${colors.orange};

		&::after {
			background-color: ${colors.orange};
		}
	}
`

const StyledRadioButton = styled(AntdRadio.Button)`
	&.ant-radio-button-wrapper {
		background: ${colors.lightGrey};
		&:hover {
			color: ${colors.orange};
		}
	}

	&.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
		background: ${colors.orange};
		border-color: ${colors.orange};

		&:first-child {
			border: 1px solid ${colors.orange};
		}

		&:focus-within {
			box-shadow: 0 0 0 2px rgba(${colors.orange_RGB}, 0.2);
		}

		&:hover {
			background: ${colors.orange};
			border-color: ${colors.orange};
		}

		&::before {
			background-color: ${colors.orange};
		}
	}
`

const Radio = props => <StyledRadio {...props} />

Radio.Group = props => <AntdRadio.Group {...props} />
Radio.Group.displayName = "RadioGroup"

Radio.Button = props => <StyledRadioButton {...props} />
Radio.Button.displayName = "RadioButton"

export default Radio
