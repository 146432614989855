import React, { forwardRef, useImperativeHandle, useState } from "react"
import { Form, Select as AntdSelect } from "antd"
import { useTranslation } from "react-i18next"
import PropTypes from "prop-types"
import styled from "styled-components"

import { updateUserData } from "../utils/firebaseHelpers"
import usaStates from "../utils/usaStates"
import usaCities from "../utils/usaCities"
import { isValidUSZip, resolveErrorMessage } from "../utils/tools"
import colors from "../theme/colors"

import Button from "./Button"
import Input from "./Input"
import Select from "./Select"
import Radio from "./Radio"
import Checkbox from "./Checkbox"
import ResetPasswordButton from "./ResetPasswordButton"
import Autocomplete from "./Autocomplete"

const { Option } = AntdSelect

const StyledLabel = styled("span")`
	text-transform: capitalize;

	&.styled-group-label {
		color: ${colors.drakGrey};
		font-size: 16px;
	}

	&.styled-item-label {
		color: ${colors.darkGrey};
		font-size: 12px;
		font-family: "Poppins-SemiBold";
	}
`

const StyledButton = styled(Button)`
	font-family: "Poppins-SemiBold";
	text-transform: uppercase;
	min-width: 130px;
`

const StyledRadio = styled(Radio)`
	display: block;
	padding: 5px 0px;
`

const StyledForm = styled(Form)`
	> .ant-form-item {
		padding-top: 15px;

		&:first-child {
			padding-top: 0;
		}
	}
`

const AccountHolderFormItem = styled(Form.Item)`
	text-align: center;
	.ant-checkbox-wrapper {
		background: ${colors.lightGrey};
		padding: 15px 20px;
		margin: 0;
	}
`

const FormItemsGroup = styled(Form.Item)`
	margin-bottom: 0px;
`

const UserInfoForm = forwardRef((props, ref) => {
	const {
		onSuccess,
		onFinish,
		onFinishFailed,
		saveOnFinish,
		formName,
		userData,
		isAuthUser,
		hideActionButtons,
	} = props
	const [loading, setLoading] = useState(false)
	const [changed, setChanged] = useState(false)
	const [registerErrorMsg, setRegisterErrorMsg] = useState(null)
	const { t } = useTranslation()
	const [form] = Form.useForm()

	useImperativeHandle(ref, () => ({
		submitForm() {
			if (form && form.submit) {
				form.submit()
			}
		},

		scrollToField(name) {
			if (form && form.scrollToField) {
				form.scrollToField(name, { behavior: "smooth" })
			}
		},

		getFieldsValue() {
			if (form && form.getFieldsValue) {
				return form.getFieldsValue()
			}

			return null
		},

		async validateFields() {
			if (form && form.validateFields) {
				return form
					.validateFields()
					.then(values => ({ values, valid: true }))
					.catch(error => ({ valid: false, ...error }))
			}
			return null
		},
	}))

	const _runSuccess = firstName => {
		if (onSuccess) {
			setLoading(false)
			setChanged(false)
			onSuccess(firstName)
		}
	}

	const _saveForm = async values => {
		const { firstName } = values
		if (userData.id) {
			const { error } = await updateUserData(userData.id, values)

			if (error) {
				setLoading(false)
				const msg = t(resolveErrorMessage(error))
				setRegisterErrorMsg(msg)
			} else {
				_runSuccess(firstName)
			}
		}
	}

	const _onFinish = async values => {
		setRegisterErrorMsg(null)

		if (onFinish) {
			onFinish(values)
		}

		if (saveOnFinish) {
			_saveForm(values)
		}
	}

	const _onFinishFailed = ({ errorFields }) => {
		if (onFinishFailed) {
			onFinishFailed(errorFields)
		}

		setLoading(false)
	}

	const _onReset = () => {
		form.resetFields()
		setChanged(false)
	}

	return (
		<StyledForm
			style={{
				margin: `auto`,
				maxWidth: `700px`,
			}}
			layout="vertical"
			name={formName || "infoForm"}
			initialValues={{ remember: true }}
			onFinish={_onFinish}
			onFinishFailed={_onFinishFailed}
			form={form}
			onValuesChange={() => setChanged(true)}
			hideRequiredMark
		>
			{isAuthUser &&
			userData.caregiverEmail &&
			userData.caregiverEmail.length ? (
				<AccountHolderFormItem
					name="accountHolderEdit"
					valuePropName="checked"
					initialValue={userData.accountHolderEdit || false}
				>
					<Checkbox>{t("forms:allowAccountHolder")}</Checkbox>
				</AccountHolderFormItem>
			) : null}
			<FormItemsGroup
				label={
					<StyledLabel className="styled-group-label">
						{t("common:name")}*
					</StyledLabel>
				}
			>
				<Form.Item
					label={
						<StyledLabel
							className="styled-item-label"
							title={t("common:firstName")}
						>
							{t("common:firstName")}
						</StyledLabel>
					}
					name="firstName"
					validateTrigger="onSubmit"
					initialValue={userData.firstName || ""}
					rules={[
						{
							required: true,
							message: t("forms:requiredField"),
						},
					]}
					style={{ display: "inline-block", width: "calc(50% - 10px)" }}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label={
						<StyledLabel
							className="styled-item-label"
							title={t("common:firstName")}
						>
							{t("common:lastName")}
						</StyledLabel>
					}
					name="lastName"
					validateTrigger="onSubmit"
					initialValue={userData.lastName || ""}
					rules={[
						{
							required: true,
							message: t("forms:requiredField"),
						},
					]}
					style={{
						display: "inline-block",
						width: "calc(50% - 10px)",
						marginLeft: "20px",
					}}
				>
					<Input />
				</Form.Item>
			</FormItemsGroup>

			<Form.Item
				label={
					<StyledLabel className="styled-group-label">
						{t("common:email")}
					</StyledLabel>
				}
				name="primaryEmail"
				validateTrigger="onSubmit"
				initialValue={userData.primaryEmail || ""}
				rules={[
					{
						type: "email",
						message: t("forms:invalidEmail"),
					},
				]}
			>
				<Input disabled={userData && userData.primaryEmail} />
			</Form.Item>

			{isAuthUser ? (
				<FormItemsGroup
					label={
						<StyledLabel className="styled-group-label">
							{t("common:password")}
						</StyledLabel>
					}
				>
					<ResetPasswordButton email={userData.primaryEmail || ""} />
				</FormItemsGroup>
			) : null}

			<FormItemsGroup
				label={
					<StyledLabel className="styled-group-label">
						{t("common:phone")}*
					</StyledLabel>
				}
			>
				<Form.Item
					name="phone"
					initialValue={userData.phone || ""}
					validateTrigger="onSubmit"
					style={{
						display: "inline-block",
						width: "50%",
					}}
					rules={[
						{
							required: true,
							message: t("forms:requiredField"),
						},
					]}
				>
					<Input placeholder="(xxx) xxx-xxxx" />
				</Form.Item>
			</FormItemsGroup>

			<Form.Item
				label={
					<StyledLabel className="styled-group-label">
						{t("common:howToBeContacted")}*
					</StyledLabel>
				}
				name="contactMethod"
				validateTrigger="onSubmit"
				initialValue={userData.contactMethod || []}
				rules={[
					{
						required: true,
						message: t("forms:requiredField"),
					},
				]}
			>
				<Checkbox.Group>
					<Checkbox value="Email">{t("common:email")}</Checkbox>
					<Checkbox value="Phone">{t("common:phone")}</Checkbox>
				</Checkbox.Group>
			</Form.Item>

			<Form.Item
				label={
					<StyledLabel className="styled-group-label">
						{t("common:howToSummaries")}*
					</StyledLabel>
				}
				name="summariesMethod"
				validateTrigger="onSubmit"
				initialValue={userData.summariesMethod || []}
				rules={[
					{
						required: true,
						message: t("forms:requiredField"),
					},
				]}
			>
				<Checkbox.Group>
					<Checkbox value="Email">{t("common:email")}</Checkbox>
					<Checkbox value="Regular Mail">{t("common:regularMail")}</Checkbox>
				</Checkbox.Group>
			</Form.Item>

			<FormItemsGroup
				label={
					<StyledLabel className="styled-group-label">
						{t("common:address")}
					</StyledLabel>
				}
			>
				<Form.Item
					label={
						<StyledLabel
							className="styled-item-label"
							title={t("common:address")}
						>
							{t("common:address")}
						</StyledLabel>
					}
					name="address"
					validateTrigger="onSubmit"
					initialValue={userData.address || ""}
				>
					<Input />
				</Form.Item>

				<Form.Item
					label={
						<StyledLabel
							className="styled-item-label"
							title={t("common:state")}
						>
							{t("common:state")}
						</StyledLabel>
					}
					name="state"
					validateTrigger="onSubmit"
					initialValue={userData.state || ""}
					style={{
						display: "inline-block",
						width: "calc(40% - 13px)",
					}}
				>
					<Select>
						{Object.values(usaStates).map(state => (
							<Option key={`state-select-${state.short}`} value={state.short}>
								({state.short}) {state.long}
							</Option>
						))}
					</Select>
				</Form.Item>

				<Form.Item
					label={
						<StyledLabel className="styled-item-label" title={t("common:city")}>
							{t("common:city")}
						</StyledLabel>
					}
					name="city"
					validateTrigger="onSubmit"
					initialValue={userData.city || ""}
					style={{
						display: "inline-block",
						width: "calc(40% - 13px)",
						marginLeft: "20px",
					}}
				>
					<Autocomplete
						options={usaCities.map(c => ({ lable: c, value: c }))}
						filterOption={(inputValue, option) =>
							option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
							-1
						}
					/>
				</Form.Item>

				<Form.Item
					label={
						<StyledLabel
							className="styled-item-label"
							title={t("common:zipCode")}
						>
							{t("common:zipCode")}
						</StyledLabel>
					}
					name="zip"
					validateTrigger="onSubmit"
					initialValue={userData.zip || ""}
					rules={[
						{ required: false },
						() => ({
							validator(rule, value) {
								if (value.length === 0 || isValidUSZip(value)) {
									return Promise.resolve()
								}
								return Promise.reject(t("forms:invalidZip"))
							},
						}),
					]}
					style={{
						display: "inline-block",
						width: "calc(19% - 13px)",
						marginLeft: "20px",
					}}
				>
					<Input />
				</Form.Item>
			</FormItemsGroup>

			<Form.Item
				label={
					<StyledLabel className="styled-group-label">
						{t("common:livingConditions")}
					</StyledLabel>
				}
				name="livingConditions"
				validateTrigger="onSubmit"
				initialValue={userData.livingConditions || ""}
			>
				<Radio.Group>
					<StyledRadio value={t("common:liveAlone")}>{t("common:liveAlone")}</StyledRadio>
					<StyledRadio value={t("common:liveWith")}>{t("common:liveWith")}</StyledRadio>
					<StyledRadio value="other">{t("common:other")}</StyledRadio>
				</Radio.Group>
			</Form.Item>

			<Form.Item
				label={
					<StyledLabel className="styled-group-label">
						{t("common:otherNotes")}
					</StyledLabel>
				}
				name="otherNotes"
				validateTrigger="onSubmit"
				initialValue={userData.otherNotes || ""}
			>
				<Input.TextArea />
			</Form.Item>

			<Form.Item
				style={{ marginBottom: 0, textAlign: "center" }}
				validateStatus={registerErrorMsg ? "error" : "-"}
				help={registerErrorMsg}
			>
				{!hideActionButtons ? (
					<>
						<Form.Item style={{ display: "inline-block", marginBottom: 0 }}>
							<StyledButton
								htmlType="button"
								type="cancel-form"
								shape="round"
								onClick={_onReset}
								disabled={!changed || loading}
							>
								{t("common:cancel")}
							</StyledButton>
						</Form.Item>

						<Form.Item
							style={{
								display: "inline-block",
								marginBottom: 40,
								marginLeft: "20px",
							}}
						>
							<StyledButton
								type="primary"
								htmlType="submit"
								shape="round"
								loading={loading}
								onClick={() => setLoading(true)}
								disabled={!changed}
							>
								{t("common:save")}
							</StyledButton>
						</Form.Item>
					</>
				) : null}
			</Form.Item>
		</StyledForm>
	)
})

UserInfoForm.displayName = "UserInfoForm"

UserInfoForm.propTypes = {
	onSuccess: PropTypes.func,
	onFinish: PropTypes.func,
	onFinishFailed: PropTypes.func,
	isAuthUser: PropTypes.bool,
	saveOnFinish: PropTypes.bool,
	hideActionButtons: PropTypes.bool,
	formName: PropTypes.string,
	userData: PropTypes.shape({
		id: PropTypes.string,
		primaryEmail: PropTypes.string,
		firstName: PropTypes.string,
		lastName: PropTypes.string,
		address: PropTypes.string,
		state: PropTypes.string,
		city: PropTypes.string,
		zip: PropTypes.string,
		phone: PropTypes.string,
		caregiverEmail: PropTypes.string,
		livingConditions: PropTypes.string,
		contactMethod: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.string),
			PropTypes.string,
		]),
		summariesMethod: PropTypes.oneOfType([
			PropTypes.arrayOf(PropTypes.string),
			PropTypes.string,
		]),
		otherNotes: PropTypes.string,
		accountHolderEdit: PropTypes.bool,
	}),
}

UserInfoForm.defaultProps = {
	onSuccess: null,
	userData: {},
	onFinish: null,
	onFinishFailed: null,
	isAuthUser: false,
	saveOnFinish: true,
	hideActionButtons: false,
	formName: null,
}

export default UserInfoForm
