import React from "react"
import styled from "styled-components"

import colors from "../theme/colors"

const StyledLink = styled("a")`
	font-family: "Poppins-Semibold";
	margin-right: 15px;

	&.phone {
		color: black;
	}

	&.mail {
		color: ${colors.orange};
	}
`

const ContactInfo = () => (
	<p>
		<StyledLink href="tel:(646) 758-6606" className="phone">
			(646) 758-6606
		</StyledLink>
		<StyledLink href="mailto:Support@candootech.com" className="mail">
			support@candootech.com
		</StyledLink>
	</p>
)

export default ContactInfo
