import React, { useState, useEffect, useCallback } from "react"
import { useLocation, navigate } from "@reach/router"
import { parse } from "query-string"
import styled from "styled-components"

import { useDispatchContext as useCommonDispatch } from "../providers/common/provider"
import CommonActions from "../providers/common/actions"

import ScreenLoader from "../components/ScreenLoader"
import Modal from "../components/Modal"
import ContactInfo from "../components/ContactInfo"

import { isCoupleProduct } from "../utils/tools"
import appConfig from "../config/AppConfig"

import { QueryParams, Paths } from "../constants/RouterConstants"

const StyledContainer = styled("div")`
	width: 100vw;
	height: 100vh;

	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`

const StyledMessage = styled("p")``

const OnboardingV2View = () => {
	const location = useLocation()
	const searchParams = parse(location.search) || {}
	const [orderId] = useState(searchParams[QueryParams.ORDER_ID] || null)
	const [customerEmail] = useState(
		searchParams[QueryParams.CUSTOMER_EMAIL] || null
	)
	const [fncCalled, setFncCalled] = useState(false)
	const [errorModal, setErrorModal] = useState(null)
	const [orderData, setOrderData] = useState(null)
	const commonDispatch = useCommonDispatch()

	const fetchData = useCallback(async () => {
		const apiResponse = await fetch(
			`${appConfig.API_BASE}/getOnboardingData?accessToken=${
				appConfig.ACCESS_TOKEN
			}&orderId=${parseInt(orderId, 10)}&customerEmail=${customerEmail}`
		)
		const data = await apiResponse.json()
		setOrderData(data.response)
	}, [orderId])

	useEffect(() => {
		if (!orderId) {
			setErrorModal("unknown")
		}
		if (orderData && !fncCalled) {
			const {
				status,
				products,
				product,
				hasCouple,
				carereceivers,
				accountHolder,
				isGiftcard,
				isInstallationAndSetup,
			} = orderData

			if (status === "not-found") {
				// product not found
				navigate(Paths.ACCOUNT_SETUP)
			} else if (status === "product-onboarded") {
				// handle already onboarded product
				setErrorModal(status)
			} else if (status === "ready") {
				commonDispatch({
					type: CommonActions.SET_COMMON_PAYLOAD,
					payload: {
						accountHolder,
						product,
						products,
						carereceivers,
						hasCouple,
						isGiftcard,
						isInstallationAndSetup,
						isCoupleProduct: isCoupleProduct(product),
						isNewCustomer: (products && products.length === 1) || false,
					},
				})
				navigate(Paths.ACCOUNT_SETUP)
			}
			setFncCalled(true)
		}
	}, [orderData, orderId])

	useEffect(() => {
		if (orderId && !fncCalled) {
			try {
				fetchData()
			} catch (e) {
				setErrorModal("unknown")
			}
		}
	}, [orderId, fncCalled, fetchData])

	const _renderErrorDetails = () => {
		if (!errorModal) {
			return null
		}

		if (errorModal === "not-found") {
			return (
				<StyledMessage>
					You&lsquo;re all set! A customer rep from our Team will contact you to
					schedule your next appointment.
				</StyledMessage>
			)
		}

		if (errorModal === "product-onboarded") {
			return (
				<StyledMessage>
					The order was already processed. If you think this is a mistake,
					please contact support.
				</StyledMessage>
			)
		}

		return (
			<StyledMessage>
				Something went wrong processing your order. Please, try again or contact
				support if the issue persist.
			</StyledMessage>
		)
	}

	return (
		<StyledContainer>
			<ScreenLoader
				text={
					<p>
						Syncing your order
						<br /> information....
					</p>
				}
			/>
			<Modal visible={!!errorModal} closable={false} footer={null}>
				{_renderErrorDetails()}
				<ContactInfo />
			</Modal>
		</StyledContainer>
	)
}

export default OnboardingV2View
