export default {
	dev: {
		apiKey: "AIzaSyCodZ1F825I0f_bI1mbBshB0W-qeOILVzY",
		authDomain: "candoo-prototype.firebaseapp.com",
		databaseURL: "https://candoo-prototype.firebaseio.com",
		projectId: "candoo-prototype",
		storageBucket: "candoo-prototype.appspot.com",
		messagingSenderId: "320563327348",
		appId: "1:320563327348:web:b6c222d8c9f9efc34b3c21",
	},
	prod: {
		apiKey: "AIzaSyBBdg2rwidu_G3_ZkXc_O8leqkcLTUBFz0",
		authDomain: "candoo-tech.firebaseapp.com",
		databaseURL: "https://candoo-tech.firebaseio.com",
		projectId: "candoo-tech",
		storageBucket: "candoo-tech.appspot.com",
		messagingSenderId: "592640010741",
		appId: "1:592640010741:web:76d34572890c9dbaeed8bc",
		measurementId: "G-Q3VFV9RVPQ",
	}
}
