import React, { useState } from "react"
import { message as AntdMessage, Form } from "antd"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import PropTypes from "prop-types"

import firebaseInstance from "../utils/firebaseInstance"
import { resolveErrorMessage } from "../utils/tools"

import Button from "./Button"

const StyledTitle = styled("p")`
	margin-bottom: 5px;
`

const ResetPasswordButton = ({ email, onSuccess }) => {
	const [loading, setLoading] = useState(false)
	const [errorMsg, setErrorMsg] = useState(null)
	const { t } = useTranslation()

	const onClick = async () => {
		setLoading(true)
		setErrorMsg(null)

		const { error } = await firebaseInstance.resetPassword(email)

		if (error) {
			const msg = t(resolveErrorMessage(error))
			setErrorMsg(msg)
		} else {
			AntdMessage.success(t("resetPassword:resetEmailSent"))

			if (onSuccess) {
				onSuccess()
			}
		}

		setLoading(false)
	}

	return (
		<Form.Item validateStatus={errorMsg ? "error" : "-"} help={errorMsg}>
			<StyledTitle>{t("resetPassword:label")}</StyledTitle>
			<Button type="button" loading={loading} onClick={onClick}>
				{t("resetPassword:sendEmail")}
			</Button>
		</Form.Item>
	)
}

ResetPasswordButton.propTypes = {
	onSuccess: PropTypes.func,
	email: PropTypes.string,
}

ResetPasswordButton.defaultProps = {
	onSuccess: undefined,
	email: undefined,
}

export default ResetPasswordButton
